<template>
  <ele-dialog
    :isVisible="isVisible"
    :dialogTitle="editTitle"
    :dialogWidth="'48.5rem'"
    @on-cancel="$emit('update:isVisible', false)"
    @on-add="sava(elForms)"
  >
    <el-form
      v-if="dialogType == 'fly'"
      ref="elForms"
      :model="deviceFrom"
      :rules="deviceRules"
      label-width="80px"
      label-position="top"
      class="flex-fsb editbox"
    >
      <div class="left">
        <el-form-item :label="t('616')" prop="deviceName">
          <el-input
            class="common-input"
            v-model.trim="deviceFrom.deviceName"
            onkeyup="this.value=this.value.replace(/\ +/g, '')"
            :placeholder="t('1058')"
            maxlength="16"
          ></el-input>
        </el-form-item>
        <el-form-item :label="t('215')" prop="modelId">
          <div
            id="selectbox"
            class="select flex-sb"
            style="width: 100%; cursor: pointer"
            @click.stop="modelChange"
          >
            <span :class="modelName == '' ? '' : 'active_span'">
              <el-input
                v-show="showInput"
                v-model.trim="deviceFrom.modelId"
                maxlength="16"
              ></el-input
              >{{ modelName == "" ? t('1059') : modelName }}</span
            >
            <div class="hover_opacity">
              <el-icon class="select_icon" v-if="selectShow"
                ><ArrowUp
              /></el-icon>
              <el-icon class="select_icon" v-else><ArrowDown /></el-icon>
            </div>
            <ul v-if="selectShow">
              <div class="libox" ref="boxRef">
                <li
                  style="cursor: pointer"
                  v-for="(item, index) in marking"
                  :key="item.id"
                  class="flex-jcsb flex-fac"
                  @click.stop="liShow(index, item)"
                >
                  <input
                    type="text"
                    style="cursor: pointer"
                    v-if="deviceEdit"
                    :class="editIndex == index ? 'active' : ''"
                    disabled
                    v-model="item.model"
                  />
                  <input
                    v-else
                    ref="inputRef"
                    style="cursor: pointer"
                    type="text"
                    maxlength="14"
                    :class="{
                      active: editIndex == index,
                      in_border: editIndex == index && deviceEdit == false,
                    }"
                    v-model="item.model"
                    :autofocus="deviceEdit == false ? 'autofocus' : ''"
                    onkeyup="this.value=this.value.replace(/\ +/g, '')"
                    @blur="onBlur(index, item)"
                  />
                  <!--  -->
                  <span v-if="index == editIndex" class="flex-fac">
                    <el-icon
                      class="select_edit hover_opacity"
                      @click.stop="liEdit(index)"
                      ><Edit
                    /></el-icon>
                    <el-icon
                      class="element_icon hover_opacity"
                      @click.stop="liDel(index, item)"
                    >
                      <Delete />
                    </el-icon>
                  </span>
                </li>
                <div class="flex-a addbox" v-if="addShow">
                  <input
                    :placeholder="t('1060')"
                    type="text"
                    maxlength="14"
                    class="addshow"
                    :class="addShow ? 'in_border' : ''"
                    :autofocus="addShow ? 'autofocus' : ''"
                    onkeyup="this.value=this.value.replace(/\ +/g, '')"
                    v-model.trim="modelVal"
                    @input.stop="addValue"
                  />
                  <div class="add_right" @click.stop="addSubmit">{{ t('284') }}</div>
                  <div class="add_right" @click.stop="closeAdd">{{ t('217') }}</div>
                </div>
              </div>
              <div
                class="select_btn flex-fac hover_opacity"
                @click.stop="selectAdd"
              >
                <img src="@/asset/img/add.png" alt="" />
                {{ t('209') }}
              </div>
            </ul>
          </div>
        </el-form-item>
        <el-form-item :label="t('212')" prop="region">
          <el-cascader
            ref="cityRef"
            class="common-input"
            style="width: 100%"
            v-model="deviceFrom.region"
            :placeholder="t('1061')"
            :options="city"
            :props="cityCasDer"
            @change="cityChange"
            popper-class="teamedit disabled_hide_cacader"
          />
        </el-form-item>
      </div>
      <div class="right">
        <el-form-item :label="t('617')" prop="sn">
          <el-input
            class="common-input"
            v-model.trim="deviceFrom.sn"
            onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5-\ ]/g,'')"
            :placeholder="t('1062')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="t('618')" prop="number">
          <el-input
            class="common-input"
            v-model.trim="deviceFrom.number"
            onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5-\ ]/g,'')"
            :placeholder="t('1063')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="t('214')" prop="teamId">
          <el-cascader
            ref="teamRef"
            class="common-input teamc"
            style="width: 100%"
            :show-all-levels="false"
            :placeholder="t('1064')"
            v-model="deviceFrom.teamId"
            :props="organizationCasDer"
            :options="teamList"
            @change="teamChange"
            popper-class="teamedit disabled_hide_cacader"
          />
        </el-form-item>
      </div>
    </el-form>
    <el-form
      v-if="dialogType == 'airport'"
      ref="elForms"
      :model="deviceFrom"
      :rules="deviceRules"
      label-width="80px"
      label-position="top"
      class="flex-fsb editbox"
    >
      <div class="left">
        <el-form-item :label="t('616')" prop="deviceName">
          <el-input
            class="common-input"
            v-model.trim="deviceFrom.deviceName"
            onkeyup="this.value=this.value.replace(/\ +/g, '')"
            :placeholder="t('1058')"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item :label="t('1065')" prop="modelId">
          <el-select
            style="width: 100%"
            v-model="deviceFrom.modelId"
            class="m-2 common-input"
            :placeholder="t('1059')"
            @change="airportModelChange"
            clearable
          >
            <!-- 
            @clear="cleanAirport" -->
            <el-option
              v-for="item in airportModelList"
              :key="item.id"
              :label="item.model"
              :value="item.id"
            />
          </el-select>
          <!-- <div
            id="selectbox"
            class="select flex-sb"
            style="width: 100%; cursor: pointer"
            @click.stop="modelChange"
          >
            <span :class="modelName == '' ? '' : 'active_span'">
              <el-input
                v-show="showInput"
                v-model.trim="deviceFrom.modelId"
                maxlength="16"
              ></el-input
              >{{ modelName == "" ? "t('1059')" : modelName }}</span
            >
            <div class="hover_opacity">
              <el-icon class="select_icon" v-if="selectShow"
                ><ArrowUp
              /></el-icon>
              <el-icon class="select_icon" v-else><ArrowDown /></el-icon>
            </div>
            <ul v-if="selectShow">
              <div class="libox" ref="boxRef">
                <li
                  style="cursor: pointer"
                  v-for="(item, index) in marking"
                  :key="item"
                  class="flex-jcsb flex-fac"
                  @click.stop="liShow(index, item)"
                >
                  <input
                    type="text"
                    style="cursor: pointer"
                    v-if="deviceEdit"
                    :class="editIndex == index ? 'active' : ''"
                    disabled
                    v-model="item.model"
                  />
                  <input
                    v-else
                    ref="inputRef"
                    type="text"
                    style="cursor: pointer"
                    :class="{
                      active: editIndex == index,
                      in_border: editIndex == index && deviceEdit == false,
                    }"
                    v-model="item.model"
                    :autofocus="deviceEdit == false ? 'autofocus' : ''"
                    onkeyup="this.value=this.value.replace(/\ +/g, '')"
                    @blur="onBlur(index, item)"
                    maxlength="14"
                  />
                  <span v-if="index == editIndex" class="flex-fac">
                    <el-icon
                      class="select_edit hover_opacity"
                      @click.stop="liEdit(index)"
                      ><Edit
                    /></el-icon>
                    <el-icon
                      class="element_icon hover_opacity"
                      @click.stop="liDel(index, item)"
                    >
                      <Delete />
                    </el-icon>
                  </span>
                </li>
                <div class="flex-a addbox" v-if="addShow">
                  <input
                    placeholder="t('1060')"
                    type="text"
                    maxlength="14"
                    class="addshow"
                    :class="addShow ? 'in_border' : ''"
                    :autofocus="addShow ? 'autofocus' : ''"
                    onkeyup="this.value=this.value.replace(/\ +/g, '')"
                    v-model.trim="modelVal"
                    @input.stop="addValue"
                  />
                  <div class="add_right" @click.stop="addSubmit">{{ t('284') }}</div>
                  <div class="add_right" @click.stop="closeAdd">{{ t('217') }}</div>
                </div>
              </div>
              <div
                class="select_btn flex-fac hover_opacity"
                @click.stop="selectAdd"
              >
                <img src="@/asset/img/add.png" alt="" />
                添加
              </div>
            </ul>
          </div> -->
        </el-form-item>
        <el-form-item :label="t('212')" prop="region">
          <el-cascader
            ref="cityRef"
            class="common-input"
            style="width: 100%"
            v-model="deviceFrom.region"
            :placeholder="t('1066')"
            :options="city"
            :props="cityCasDer"
            @change="cityChange"
            popper-class="teamedit disabled_hide_cacader"
          />
        </el-form-item>
      </div>
      <div class="right">
        <el-form-item :label="t('617')" prop="sn">
          <el-input
            class="common-input"
            v-model.trim="deviceFrom.sn"
            onkeyup="this.value=this.value.replace(/\ +/g,'')"
            :placeholder="t('1062')"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item :label="t('618')" prop="number">
          <el-input
            class="common-input"
            v-model.trim="deviceFrom.number"
            onkeyup="this.value=this.value.replace(/\ +/g,'')"
            :placeholder="t('1063')"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item :label="t('214')" prop="teamId">
          <el-cascader
            ref="teamRef"
            class="common-input teamc"
            style="width: 100%"
            :show-all-levels="false"
            :placeholder="t('1064')"
            v-model="deviceFrom.teamId"
            :props="organizationCasDer"
            :options="teamList"
            @change="teamChange"
            popper-class="teamedit disabled_hide_cacader"
          />
        </el-form-item>
      </div>
    </el-form>

    <el-form
      v-if="dialogType == 'asset'"
      ref="elForms"
      :model="deviceFrom"
      :rules="deviceRules"
      label-width="80px"
      label-position="top"
      class="flex-fsb editbox"
    >
      <div class="left">
        <el-form-item :label="t('616')" prop="deviceName">
          <el-input
            class="common-input"
            v-model.trim="deviceFrom.deviceName"
            onkeyup="this.value=this.value.replace(/\ +/g, '')"
            :placeholder="t('1058')"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item :label="t('215')" prop="modelId">
          <div
            id="selectbox"
            class="select flex-sb"
            style="width: 100%; cursor: pointer"
            @click.stop="modelChange"
          >
            <span :class="modelName == '' ? '' : 'active_span'">
              <el-input
                v-show="showInput"
                v-model.trim="deviceFrom.modelId"
                maxlength="20"
              ></el-input
              >{{ modelName == "" ? t('1059') : modelName }}</span
            >
            <div class="hover_opacity">
              <el-icon class="select_icon" v-if="selectShow"
                ><ArrowUp
              /></el-icon>
              <el-icon class="select_icon" v-else><ArrowDown /></el-icon>
            </div>
            <ul v-if="selectShow">
              <div class="libox" ref="boxRef">
                <li
                  style="cursor: pointer"
                  v-for="(item, index) in marking"
                  :key="item"
                  class="flex-jcsb flex-fac"
                  @click.stop="liShow(index, item)"
                >
                  <!-- <input
                    type="text"
                    style="cursor: pointer"
                    v-if="deviceEdit"
                    :class="editIndex == index ? 'active' : ''"
                    disabled
                    v-model="item.model"
                  /> -->
                  <span style="cursor: pointer;width:100%;hieght:100%"
                    class="device-span"
                    v-if="deviceEdit" :class="editIndex == index ? 'active' : ''" @click.stop="liShow(index, item)">{{item.model}}</span>
                  <input
                    v-else
                    ref="inputRef"
                    type="text"
                    style="cursor: pointer"
                    :class="{
                      active: editIndex == index,
                      in_border: editIndex == index && deviceEdit == false,
                    }"
                    v-model="item.model"
                    :autofocus="deviceEdit == false ? 'autofocus' : ''"
                    onkeyup="this.value=this.value.replace(/\ +/g, '')"
                    @blur="onBlur(index, item)"
                    maxlength="14"
                  />
                  <span v-if="index == editIndex" class="flex-fac">
                    <el-icon
                      class="select_edit hover_opacity"
                      @click.stop="liEdit(index)"
                      ><Edit
                    /></el-icon>
                    <el-icon
                      class="element_icon hover_opacity"
                      @click.stop="liDel(index, item)"
                    >
                      <Delete />
                    </el-icon>
                  </span>
                </li>
                <div class="flex-a addbox" v-if="addShow">
                  <input
                    :placeholder="t('1060')"
                    type="text"
                    maxlength="14"
                    class="addshow"
                    :class="addShow ? 'in_border' : ''"
                    :autofocus="addShow ? 'autofocus' : ''"
                    onkeyup="this.value=this.value.replace(/\ +/g, '')"
                    v-model.trim="modelVal"
                    @input.stop="addValue"
                  />
                  <div class="add_right" @click.stop="addSubmit">{{ t('284') }}</div>
                  <div class="add_right" @click.stop="closeAdd">{{ t('217') }}</div>
                </div>
              </div>
              <div
                class="select_btn flex-fac hover_opacity"
                @click.stop="selectAdd"
              >
                <img src="@/asset/img/add.png" alt="" />
                {{ t('209') }}
              </div>
            </ul>
          </div>
        </el-form-item>
        <el-form-item :label="t('212')" prop="region">
          <el-cascader
            ref="cityRef"
            class="common-input"
            style="width: 100%"
            v-model="deviceFrom.region"
            :placeholder="t('1066')"
            :options="city"
            :props="cityCasDer"
            @change="cityChange"
            popper-class="teamedit disabled_hide_cacader"
          />
        </el-form-item>
        <el-form-item :label="t('1067')" prop="airport">
          <el-select
            style="width: 100%"
            v-model="deviceFrom.airport"
            class="m-2 common-input"
            :placeholder="t('1068')"
            @change="airportModelChange"
            clearable
          >
            <!-- 
            @clear="cleanAirport" -->
            <el-option
              v-for="item in airportList"
              :key="item.id"
              :label="item.deviceName"
              :value="item.sn"
            />
          </el-select>
        </el-form-item>
      </div>
      <div class="right">
        <el-form-item :label="t('617')" prop="sn">
          <el-input
            class="common-input"
            v-model.trim="deviceFrom.sn"
            onkeyup="this.value=this.value.replace(/\ +/g,'')"
            :placeholder="t('1062')"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item :label="t('618')" prop="number">
          <el-input
            class="common-input"
            v-model.trim="deviceFrom.number"
            onkeyup="this.value=this.value.replace(/\ +/g,'')"
            :placeholder="t('1063')"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item :label="t('214')" prop="teamId">
          <el-cascader
            ref="teamRef"
            class="common-input teamc"
            style="width: 100%"
            :show-all-levels="false"
            :placeholder="t('1064')"
            v-model="deviceFrom.teamId"
            :props="organizationCasDer"
            :options="teamList"
            @change="teamChange"
            popper-class="teamedit disabled_hide_cacader"
          />
        </el-form-item>
      </div>
    </el-form>
  </ele-dialog>
</template>

<script>
import { t } from '../../../languages';
import {
  defineComponent,
  onMounted,
  reactive,
  watchEffect,
  toRefs,
  ref,
  nextTick,
} from "vue";
import members from "@/network/member";
import device from "@/network/device";
import taskManage from "@/network/task";
import emitter from "../../../utils/mitt";
import { ElMessage } from "element-plus";
import { store } from "@/store";
import {
  findP,
  flatten,
  openRegionAdcode,
  findChildren,
} from "@/utils/openRegion";
export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    editTitle: {
      type: String,
      default: () => {},
    },
    dialogType: {
      type: String,
      default: () => {},
    },
    itemMember: {
      type: Object,
      default: () => {},
    },
  },
  emits: [""],
  setup(props, { emit }) {
    const { dialogType } = toRefs(props);
    const boxRef = ref(null);
    const inputRef = ref(null);
    const cityRef = ref(null);
    const teamRef = ref(null);
    // 设备型号
    const effect = async () => {
      if (dialogType.value == "fly") {
        let res = await device.getUavModelList();
        if (res.resultStatus) {
          state.marking = res.resultData;
          state.markingType = "UAV";
        }
      }
      if (dialogType.value == "airport") {
        // let res = await device.getAirportModelList();
        let res = await device.getDeviceModelAirportList();
        if (res.resultStatus) {
          console.log(res.resultData);
          state.airportModelList = res.resultData;
          state.markingType = "AIRPORT";
        }
      }
      if (dialogType.value == "asset") {
        let res = await device.getOtherModelList();
        if (res.resultStatus) {
          state.marking = res.resultData;
          state.markingType = "OTHER";
        }
      }
    };
    watchEffect(effect);

    fetch("json/area-tree.json")
      .then((d) => d.json())
      .then((res) => {
        const arr = store.state.system.openRegion;
        const isCountry = arr.some((item) => {
          return item === 1;
        });
        if (isCountry) {
          state.city = res[0].children;
        } else {
          const arr1 = arr.map((item) => {
            return findP(item, res[0].children);
          });
          const treeAdcode = openRegionAdcode(res[0].children); //这个树的全部adcode
          const arr2 = flatten(arr1); //需要的  ,这里还去少区数据
          const arr3 = arr2.map((item) => {
            return findChildren(item, res[0].children); //补上缺少的区adcode
          });

          const needAdcode = Array.from(new Set([...arr2, ...flatten(arr3)]));
          const notAdcode = treeAdcode.filter(
            (v) => needAdcode.findIndex((el) => el === v) === -1
          ); //去重过滤需要的adcode
          const city = deepCopyIsOpenRegion(res[0].children); //添加禁用属性
          const needCity = delIsOpenRegion(city);
          state.city = needCity;

          // 递归遍历修改数据
        function deepCopyIsOpenRegion(source) {
          const target = []; //Array.isArray(source) ? [] : {}; //数组兼容
          for (const k in source) {
            if (typeof source[k] === "object") {
              target[k] = deepCopyIsOpenRegion(source[k]);
            } else {
              target[k] = source[k];
            }
          }
          if (notAdcode.length != 0) {
            notAdcode.map((item) => {
              if (target.adcode === item) {
                target.disabled = true;
              }
            });
          }
          return target;
        }
        }

        if (dialogType.value == "fly") {
          // 新增拿上一次选择数据
          let flyCity = JSON.parse(localStorage.getItem("flyCity"));
          let flyTramId = JSON.parse(localStorage.getItem("flyTramId"));
          state.deviceFrom.region = flyCity;
          state.deviceFrom.teamId = flyTramId;
        }
        if (dialogType.value == "airport") {
          // 新增拿上一次选择数据
          let airportCity = JSON.parse(localStorage.getItem("airportCity"));
          let airportId = JSON.parse(localStorage.getItem("airportId"));
          state.deviceFrom.region = airportCity;
          state.deviceFrom.teamId = airportId;
        }
        if (dialogType.value == "asset") {
          // 新增拿上一次选择数据
          let otherCity = JSON.parse(localStorage.getItem("otherCity"));
          let otherId = JSON.parse(localStorage.getItem("otherId"));
          state.deviceFrom.region = otherCity;
          state.deviceFrom.teamId = otherId;
        }

        // 去除禁用的数据
        function delIsOpenRegion(source) {
          const target = []; //Array.isArray(source) ? [] : {}; //数组兼容
          source.map((item) => {
            if (item.disabled != true) {
              target.push(item);
            }
          });
          return target;
        }
      });
    // 自定义下拉组件数据
    const state = reactive({
      deviceFrom: {
        deviceName: "", //设备名称
        modelId: "", //设备型号
        region: [], //地区
        sn: "", //设备sn
        airport: "", //分配机场sn
        number: "", //设备编号
        teamId: "", //团队
      },
      //隐藏
      showInput: false,
      // 失焦判空刷新
      Flg: true,
      //是否显示设备型号下拉
      selectShow: false,
      //编辑模式
      deviceEdit: true,
      // 是否可编辑
      editDisable: true,
      editModelIndex: 0, //选项编辑索引
      // 显示索引
      editIndex: 0,
      //添加数据
      addShow: false,
      // 修改cascader默认配置
      cityCasDer: {
        value: "name",
        label: "title",
        disabled: "disabled",
        checkStrictly: true,
        children: "children",
        expandTrigger: "hover",
      },
      // 修改cascader默认配置
      organizationCasDer: {
        value: "id",
        label: "teamName",
        checkStrictly: true,
        children: "children",
        expandTrigger: "hover",
      },

      // 机场型号
      airportModelList: [],
      // 设备型号
      marking: [],
      // 设备类型
      markingType: "",
      modelName: "",
      modelVal: "",
      deviceRules: {
        deviceName: [
          { required: true, message: t("1058"), trigger: "blur" },
        ],
        modelId: [
          { required: true, message: t("1059"), trigger: "change" },
        ],
        region: [
          { required: true, message: t("1061"), trigger: "change" },
        ],
        sn: [{ required: true, message: t("1062"), trigger: "blur" },
             { validator: isChinese, trigger: "blur" }
        ],
        number: [
          { required: false, message: t("1063"), trigger: "blur" },
          { validator: noChinese, trigger: "blur" }
        ],
        airport: [
          { required: true, message: t("1068"), trigger: "change" }
        ],
        teamId: [
          { required: true, message: t("1064"), trigger: "change" },
        ],
      },
      // 地区
      city: [],
      // 团队
      teamList: [],
      // 分配机场列表
      airportList:[],
    });

    function isChinese(rule, value, callback) {
      if (!isNumber(value)) {
          callback(new Error(t("1069")));
        } else {
          callback();
      }
    } 
    function isNumber(value) {
      return /^[0-9a-zA-Z-_]+$/.test(value);
    }
    function noChinese(rule, value, callback){
      let rexp = /[\u4E00-\u9FA5]/
      if (rexp.test(value)) {
          callback(new Error(t("1070")));
        } else {
          callback();
      }
    }


    const getDeviceList = async () => {
    const res = await taskManage.getDeviceList({
      deviceType: "AIRPORT",
      });
      if(res.resultStatus){
        state.airportList = res.resultData;
      }
    };
    getDeviceList();

    onMounted(() => {
      methods.team();
      // 通过js事件关闭设备型号列表
      nextTick(() => {
        document.querySelector(".center_dialog ").onclick = function () {
          state.selectShow = false;
          state.addShow = false;
        };
      });
    });
    //规则校验
    const elForms = ref(null);
    const methods = reactive({
      // 所属团队
      team: async () => {
        let res = await members.getTreeList();
        state.teamList = res.resultData;
      },
      sava: () => {
        elForms.value.validate(async (valid) => {
          console.log(valid);
          console.log(state.deviceFrom.sn);
          if (valid) {
            let city = state.deviceFrom.region;
            let teamId = "";
            if (typeof state.deviceFrom.teamId == "object") {
              let team = state.deviceFrom.teamId.length - 1;
              teamId = state.deviceFrom.teamId[team];
            } else {
              teamId = state.deviceFrom.teamId;
            }
            let region = "";
            if (city.length == 1) {
              region = `${city[0]}`;
            } else if (city.length == 2) {
              region = `${city[0]};${city[1]}`;
            } else if (city.length == 3) {
              region = `${city[0]};${city[1]};${city[2]}`;
            }
            // 机场参数
            let submit = {};
            submit.deviceName = state.deviceFrom.deviceName;
            submit.modelId = state.deviceFrom.modelId;
            submit.number = state.deviceFrom.number;
            submit.sn = state.deviceFrom.sn;
            submit.region = region;
            submit.teamId = teamId;
            //配件参数
            let otherobj = {}
            otherobj.accessoryName = state.deviceFrom.deviceName;
            otherobj.modelId = state.deviceFrom.modelId;
            otherobj.accessoryNumber = state.deviceFrom.number;
            otherobj.accessorySn = state.deviceFrom.sn;
            otherobj.deviceSN = state.deviceFrom.airport;
            otherobj.region = region;
            otherobj.teamId = teamId;
            if(dialogType.value == "airport"){
              let res = await device.addDevice(submit);
               if (res.resultStatus) {
                  ElMessage.success(t("713"));
                  emit("addDevice");
                }
            }else{
              let res = await device.addAccessory(otherobj);
              if (res.resultStatus) {
                ElMessage.success(t("713"));
                emit("addDevice");
              }
            }
            
          } else {
            return false;
          }
        });
      },
      //选择地区
      cityChange: () => {
        if (dialogType.value == "fly") {
          let flyCity = JSON.stringify(state.deviceFrom.region);
          localStorage.setItem("flyCity", flyCity); //存本地
        }
        if (dialogType.value == "airport") {
          let airportCity = JSON.stringify(state.deviceFrom.region);
          localStorage.setItem("airportCity", airportCity); //存本地
        }
        if (dialogType.value == "asset") {
          let otherCity = JSON.stringify(state.deviceFrom.region);
          localStorage.setItem("otherCity", otherCity); //存本地
        }
        cityRef.value.togglePopperVisible();
      },
      // 选择团队
      teamChange: (id) => {
        let len = id.length - 1;
        if (dialogType.value == "fly") {
          let flyTramId = JSON.stringify(id[len]);
          localStorage.setItem("flyTramId", flyTramId); //存本地
        }
        if (dialogType.value == "airport") {
          let airportId = JSON.stringify(id[len]);
          localStorage.setItem("airportId", airportId); //存本地
        }
        if (dialogType.value == "asset") {
          let otherId = JSON.stringify(id[len]);
          localStorage.setItem("otherId", otherId); //存本地
        }
        teamRef.value.togglePopperVisible();
      },

      //显示下拉列表
      modelChange: () => {
        if (!state.addShow) {
          state.selectShow = !state.selectShow;
          state.addShow = false;
          state.modelName = state.marking[state.editIndex].model;
          state.deviceFrom.modelId = state.marking[state.editIndex].id;
        }
      },
      // 添加
      selectAdd: () => {
        state.addShow = true;
        state.editDisable = true;
        state.modelVal = ""; //不保留上一次输入数据
        if (state.marking.length >= 3) {
          nextTick(() => {
            boxRef.value.scrollTo(0, boxRef.value.scrollHeight); //数据多滑动底部
          });
        }
      },
      // 关闭新增
      closeAdd: () => {
        state.addShow = false;
        state.editDisable = true;
        state.modelVal = ""; //不保留上一次输入数据
        if (state.marking.length >= 3) {
          nextTick(() => {
            boxRef.value.scrollTo(0, 0); //回顶
          });
        }
      },
      // 编辑
      liEdit: (e) => {
        // 缺插入定位，删除失败，添加插入把上一次数据清空
        state.deviceEdit = false;
        nextTick(() => {
          inputRef.value[e].focus();
        });
        state.editModelIndex = e;
        state.Flg = true;
      },
      //删除
      liDel: async (e, item) => {
        let res = await device.delDeviceModel(item.id);
        if (res.resultStatus) {
          state.marking.splice(e, 1);
          ElMessage.success(t("560"));
          effect();
          state.modelName = ""; //清空删除选中后的数据
          state.deviceFrom.modelId = "";
          emitter.emit("refreshDevice");
        }
      },
      // 显示删除编辑
      liShow: (index, item) => {
        console.log(888);
        state.editIndex = index;
        state.modelName = item.model;
        state.deviceFrom.modelId = item.id;
        state.addShow = false;
        if (index == state.editIndex) {
          return;
        } else {
          // state.editDisable = true; //不可编辑
          state.deviceEdit = true;
        }
      },
      //添加事件
      addValue: () => {
        // console.log(e.data);
        // state.selectShow = !state.selectShow;
      },
      // 添加事件失焦
      addSubmit: async () => {
        //把数据发给后台
        let obj = {};
        obj.type = state.markingType;
        obj.model = state.modelVal;
        if (state.modelVal != "" && state.addShow) {
          let res = await device.addDeviceModel(obj);
          if (res.resultStatus) {
            ElMessage.success(t("713"));
            state.addShow = false;
            effect();
            emitter.emit("refreshDevice");
          }
        }
      },
      // 编辑输入框失焦保存
      onBlur: async () => {
        let edit = {};
        let obj = state.marking[state.editModelIndex];
        edit.type = obj.deviceTypeEnum;
        edit.id = obj.id;
        edit.model = obj.model;
        let res = await device.editDeviceModel(edit);
        if (res.resultStatus && state.Flg) {
          // state.editDisable = true; //不可编辑
          state.deviceEdit = true;
          effect();
          state.modelName = obj.model;
          ElMessage.success(t("714"));
          state.Flg = false;
          emitter.emit("refreshDevice");
        }
        if (res.resultStatus == false) {
          effect();
          state.Flg = false;
          // state.editDisable = true; //不可编辑
          state.deviceEdit = true;
        }
      },
    });

    // 选择机场型号
    const airportModelChange = () => {
      console.log(state.deviceFrom.modelId);
      // state.deviceFrom.modelId = "";
    };
    const cleanAirport = () => {};

    return {
      ...toRefs(state),
      ...toRefs(methods),
      elForms,
      boxRef,
      inputRef,
      airportModelChange,
      cleanAirport,
      cityRef,
      teamRef
    };
  },
});
</script>

<style>
/* 去除校验边框 */
.el-form-item.is-error .el-input__wrapper {
  box-shadow: 0 0 0 0 #f56c6c inset !important;
}
/* 地区禁用样式背景变白导致看不见字体 */
.el-radio__input.is-disabled .el-radio__inner {
  background-color: rgba(0, 0, 0, 0) !important;
}
</style>
<style lang="less" scoped>
/deep/.el-dialog__header .el-dialog__title {
  color: #ffffff !important;
}
/deep/.el-form-item {
  margin-bottom: 2rem;
}
/deep/.el-form-item .el-form-item__label {
  margin-bottom: 0.25rem !important;
  color: #ffffff;
}
/deep/.el-dialog__close {
  color: #ffffff !important;
}
/deep/.el-form--default.el-form--label-top .el-form-item .el-form-item__label {
  margin-bottom: 0;
}
.editbox {
  margin-bottom: 2.5rem;
}
.left {
  width: 20rem;
}
.right {
  width: 20rem;
}
/deep/.common-input .el-input__wrapper {
  background: #001c31 !important;
}
/deep/ .el-input__wrapper {
  background: #001c31 !important;
  height: 2.5rem !important;
  border-radius: 0 !important;
}
/deep/ .el-input__inner {
  color: #ffffff;
  height: 2.5rem !important;
}
.select {
  position: relative;
  width: 100%;
  height: 2.5rem;
  background: #001c31;
  box-sizing: border-box;
  border: 1px solid #51d2ff;
  // border-radius: 4px;
  padding: 0.8125rem 0.75rem;
  span {
    color: #a9b8c3;
  }
  .active_span {
    color: #ffffff;
  }
  .select_icon {
    color: #a9b8c3;
  }
  ul {
    position: absolute;
    top: 2.5rem;
    left: 0;
    width: 100%;
    height: 10.75rem;
    background: #ffffff;
    border-radius: 0.25rem;
    z-index: 33;
    .libox {
      width: 100%;
      height: 10.6875rem;
      overflow: hidden;
      overflow-y: auto;
    }
    li {
      height: 2.1875rem;
      line-height: 2.1875rem;
      padding: 0.3125rem 0.6875rem 0.1875rem 1.875rem;
      color: #666666;
      cursor: pointer;
      box-sizing: border-box;
    }
    .device-span{
      color: #666666;
    }
    .active {
      color: #138fcf;
    }
    .in_border {
      box-shadow: 0 0 0 1px #138fcf inset;
      // border: .0625rem solid #138FCF;
    }
    input {
      height: 100%;
      width: 12.5rem;
      outline: 0;
      box-sizing: border-box;
      color: #333333;
      border: 0;
      background: #ffffff;
    }
    input::-webkit-input-placeholder {
      color: #a9b8c3;
    }
    .addbox {
      padding: 0.3125rem 0 0.3125rem 0;
      .add_right {
        font-size: 0.75rem;
        width: 3.5rem;
        height: 2.1875rem;
        line-height: 2.1875rem;
        text-align: center;
        color: #138fcf;
        z-index: 22;
        margin-right: 0.3125rem;
        cursor: pointer;
      }
    }
    .addshow {
      width: 100%;
      margin-left: 1.875rem;
      height: 2.1875rem;
      line-height: 2.1875rem;
      padding: 0 0 0 0.3125rem;
      z-index: 22;
      box-sizing: border-box;
      // border-radius: 0 !important;
    }
    .select_edit {
      font-size: 0.9375rem;
      color: #138fcf;
      margin-right: 1.1875rem;
    }
    .element_icon {
      font-size: 0.9375rem;
      color: red;
    }
    .select_btn {
      width: 100%;
      height: 40px;
      background: #138fcf;
      border-radius: 0px 0px 4px 4px;
      img {
        height: 1.125rem;
        width: 1.125rem;
        margin-right: 0.4375rem;
      }
      .select_add {
        background: #ffffff;
      }
    }
  }
}
</style>
