<template>
  <div class="detail" v-if="dialogType != 'asset'">
    <div class="close flex-fe">
      <el-icon class="cicon" style="cursor: pointer" @click="closeDetail">
        <CloseBold />
      </el-icon>
    </div>
    <div class="detailBox flex">
      <!-- 视图 -->
      <div class="boxLeft" id="cesiumContainer">
        <div class="layer_div" v-if="dialogType == 'airport'">
          <div class="codeImg">
            <div class="elsearch flex-a">
              <el-input class="filePut" type="text" :placeholder="t('286')" clearable v-model="layerFilterWord"
                @change="layerChange" />
              <div class="file_icon flex-fac" @click="filterLayer">
                <el-icon style="cursor: pointer">
                  <Search />
                </el-icon>
              </div>
            </div>
            <div class="laylist">
              <div class="flex flex-sb itemTop" v-for="item in filterLayerList" :key="item.layerId"
                @click.self="zoomToLayer(item)">
                <span :title="item.layerName" class="overflow_text">{{
                  item.layerName
                }}</span>
                <el-checkbox v-model="item.displayStatus" @change="changeLayarVisible(item)"></el-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="region_div">
          <div class="region_div_list" v-show="!add_no_flv_div_show">
            <span class="region_text" @click="openNoFlyShow">
              <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">{{ t('455')
              }}</el-checkbox>
              <span class="icon_style"><el-icon v-if="no_fly_div_show">
                  <ArrowUp />
                </el-icon>
                <el-icon v-else>
                  <ArrowDown />
                </el-icon>
              </span>
            </span>
            <div class="no_fly_div" v-show="no_fly_div_show">
              <div class="checkBox_div">
                <div class="flex flex-a itemTop" v-for="item in noFlyList" :key="item.regionId">
                  <el-checkbox v-model="item.visible" @change="changeNoFly(item)"></el-checkbox>
                  <span :title="item.name" class="overflow_text region_text_text">{{ item.fenceName }}</span>
                  <span class="region_text_del" @click="delNoFlyRegion(item)">{{ t('210') }}</span>
                </div>
                <div style="width: 100%; height: 100%" class="fullHeight flex flex-fac" v-if="noFlyList.length === 0">
                  {{ t('456') }}
                </div>
              </div>
              <div class="bottom_button">
                <el-button type="primary" @click="addNoFlyRegion(0)">{{ t('209') }}</el-button>
              </div>
            </div>
          </div>
          <div class="region_div_list" v-show="!add_no_flv_div_show">
            <span class="region_text" @click="openHeightLimitShow">
              <el-checkbox v-model="checkAllHeightLimit" :indeterminate="isIndeterminateHeightLimit"
                @change="handleCheckAllChangeHeightLimit">{{ t('457') }}</el-checkbox>
              <span class="icon_style"><el-icon v-if="height_limit_div_show">
                  <ArrowUp />
                </el-icon>
                <el-icon v-else>
                  <ArrowDown />
                </el-icon>
              </span>
            </span>
            <div class="no_fly_div" v-show="height_limit_div_show">
              <div class="checkBox_div">
                <div class="flex flex-a itemTop" v-for="item in heightLimitList" :key="item.regionId">
                  <el-checkbox v-model="item.visible" @change="changeHeightLimit(item)"></el-checkbox>
                  <span :title="item.name" class="overflow_text region_text_text">{{ item.fenceName }}</span>
                  <span class="region_text_del" @click="delHeightLimitRegion(item)">{{ t('210') }}</span>
                </div>
                <div style="width: 100%; height: 100%" class="fullHeight flex flex-fac"
                  v-if="heightLimitList.length === 0">
                  {{ t('456') }}
                </div>
              </div>
              <div class="bottom_button">
                <el-button type="primary" @click="addNoFlyRegion(1)">{{ t('209') }}</el-button>
              </div>
            </div>
          </div>
          <!-- 可飞区 -->
          <div class="region_div_list" v-show="!add_no_flv_div_show">
            <span class="region_text" @click="openYesFlyShow">
              <el-checkbox v-model="checkAllYesFly" @change="handleCheckAllChangeYesFly">{{ t('69') }}</el-checkbox>
              <span class="icon_style"><el-icon v-if="yes_fly_div_show">
                  <ArrowUp />
                </el-icon>
                <el-icon v-else>
                  <ArrowDown />
                </el-icon>
              </span>
            </span>
            <div class="yes_fly_div" v-show="yes_fly_div_show">
              <div class="bottom_button">
                <el-button v-if="yesFlyList.length === 0" type="primary" @click="handleYesFlyERegion('add')">{{ t('209')
                }}</el-button>
                <el-button v-else type="primary" @click="handleYesFlyERegion('edit')">{{ t('674') }}</el-button>
              </div>
            </div>
          </div>
          <!-- 网格 -->
          <div class="region_div_list" v-show="yesFlyList.length !== 0 &&
            !yes_fly_div_show &&
            !add_no_flv_div_show
            ">
            <span class="region_text">
              <el-checkbox v-model="checkAllGird" @change="handleCheckAllChangeGird">{{ t('458') }}</el-checkbox>
            </span>
          </div>
          <!-- 添加禁飞区 --><!-- 添加限高区 }} -->
          <div class="region_div_list">
            <div class="add_name_div" v-show="add_no_flv_div_show && !handleYesFlyERegionShow">
              <div class="demo-input-suffix flex flex-sb no_fly_name">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">{{ t('280') }}:</span>
                <el-input v-model.trim="noFlyName" class="w-50 m-2" maxlength="10" :placeholder="isHeightLimitShowHeight ? t('1071') : t('1072')
                  " @keyup="noFlyName = noFlyName.replace(/[ ]/g, '')" />
              </div>
              <div v-show="isHeightLimitShowHeight" class="demo-input-suffix flex flex-sb no_fly_name">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">{{ t('75') }}:</span>
                <el-input v-model.trim="heightLimitRegionHeight" class="w-50 m-2" type="number" max="10000" min="100"
                  :placeholder="t('1073')"
                  @keyup="heightLimitRegionHeight = heightLimitRegionHeight.replace(/[ ]/g, '')" />
                <b class="">m</b>
              </div>
              <div :class="isHeightLimitShowHeight ? 'checkBox_div1' : 'checkBox_div'
                ">
                <div v-for="item in noFlyPointList" :key="item.pointId" class="flex flex-sb itemTop"
                  :class="item.isActive ? 'isActive' : ''" @click="clickPoint(item)">
                  <span :title="item.name" class="overflow_text region_text_text">{{ item.name }}</span>
                  <span class="region_text_del" @click="delNoFlyPoint(item)">{{ t('210') }}</span>
                </div>
              </div>
              <div class="bottom_button flex flex-sb">
                <el-button type="primary" @click="cancelSaveNoFlyRegion(isHeightLimitShowHeight)">{{ t('217')
                }}</el-button>
                <el-button type="primary" @click="sureSaveNoFlyRegion(isHeightLimitShowHeight)">{{ t('284') }}</el-button>
              </div>
            </div>
          </div>
          <!-- 添加可飞区 -->
          <div class="region_div_list">
            <div class="add_name_div" v-show="handleYesFlyERegionShow">
              <div class="demo-input-suffix flex flex-sb no_fly_name">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">{{ t('1074') }}:</span>
                <el-input v-model="yesFlyRadius" class="w-50 m-2" :placeholder="t('1075')" />
                <b class="">m</b>
              </div>
              <div class="demo-input-suffix flex flex-sb no_fly_name">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">{{ t('75') }}:</span>
                <el-input v-model="yesFlynHeight" class="w-50 m-2" :placeholder="t('1076')" />
                <b class="">m</b>
              </div>
              <div class="demo-input-suffix flex flex-sb no_fly_name">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">{{ t('1077') }}:</span>
                <el-input v-model="yesFlyGird" class="w-50 m-2" :placeholder="t('1078')" />
                <b class="">m</b>
              </div>
              <div class="bottom_button flex flex-sb">
                <el-button type="primary" @click="cancelSaveYesFlyRegion">{{ t('217') }}</el-button>
                <el-button type="primary" @click="sureSaveYesFlyRegion">{{ t('284') }}</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 文字详情 -->
      <div class="boxRight">
        <div v-if="dialogType == 'fly'">
          <div class="Rheader flex-sb">
            <span>{{ t('1079') }}</span>
            <el-icon v-if="!editShow" class="h_icon hover_opacity" @click="edit" v-checkAuth="'device-info-update'">
              <EditPen />
            </el-icon>
            <div v-else class="flex-a">
              <div class="btn hover_opacity" @click="editClose">{{ t('217') }}</div>
              <div class="btn btn_left hover_opacity" @click="editCommit">
                {{ t('284') }}
              </div>
            </div>
          </div>
          <div class="h_content">
            <div class="list flex-a">
              <div class="listName">{{ t('616') }}</div>
              <div class="listContent" v-if="editShow == false">
                {{ flyList.deviceName }}
              </div>
              <div class="listContent" v-else>
                <input class="rolePut" v-model.trim="flyList.deviceName" :placeholder="t('668')"
                  onkeyup="this.value=this.value.replace(/\ +/g, '')" />
              </div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('829') }}SN</div>
              <div class="listContent">{{ flyList.sn }}</div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('1012') }}</div>
              <div class="listContent">{{ flyList.model }}</div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('619') }}</div>
              <div class="listContent">{{ flyList.taskNumber }}</div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('412') }}</div>
              <div class="listContent">{{ flyList.createTime }}</div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('411') }}</div>
              <div class="listContent">{{ flyList.creater }}</div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('618') }}</div>
              <div class="listContent" v-if="editShow == false">
                {{ flyList.number }}
              </div>
              <div class="listContent" v-else>
                <input class="rolePut" onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5-\ ]/g,'')"
                  v-model.trim="flyList.number" :placeholder="t('1080')" />
              </div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('1081') }}</div>
              <div class="listContent" v-if="editShow == false">
                {{ flyList.region }}
              </div>
              <el-cascader v-else class="common-input" style="width: 100%" :placeholder="t('213')"
                v-model="flyList.region" :options="cityList" :props="cityCasDer" @change="cityChange"
                popper-class="teamedit" />
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('214') }}</div>
              <div class="listContent" v-if="editShow == false">
                {{ flyList.teamName }}
              </div>
              <el-cascader v-else class="common-input" style="width: 100%" :show-all-levels="false"
                :placeholder="t('175')" v-model="flyList.teamId" :props="organizationCasDer" :options="teamList"
                @change="organiChange" popper-class="teamedit" />
            </div>
          </div>
          <div class="homeWork flex-sb">
            <span>{{ t('1082') }}</span>
            <el-icon class="h_icon hover_opacity">
              <Refresh />
            </el-icon>
          </div>
          <div class="h_content">
            <el-scrollbar>
              <!-- <div class="list flex-a" v-for="item in 6" :key="item">
                <div class="listName">{{ t('616') }}</div>
                <div class="listContent">{{ t('1083') }}</div>
                @click="getJobInfo(devsiceSn)"
              </div> -->
              <div class="list flex-a">
                <div class="listName">{{ t('1084') }}</div>
                <div class="listContent">
                  {{
                    AirportJobInfo.deviceVersion
                    ? AirportJobInfo.deviceVersion
                    : ""
                  }}
                </div>
              </div>
              <div class="list flex-a">
                <div class="listName">{{ t('1085') }}</div>
                <div class="listContent">
                  {{ AirportJobInfo.mountType ? AirportJobInfo.mountType : "" }}
                </div>
              </div>
              <div class="list flex-a">
                <div class="listName">{{ t('1086') }}</div>
                <div class="listContent">
                  {{
                    AirportJobInfo.lastTaskName
                    ? AirportJobInfo.lastTaskName
                    : ""
                  }}
                </div>
              </div>
              <div class="list flex-a">
                <div class="listName">{{ t('1087') }}</div>
                <div class="listContent">
                  {{
                    AirportJobInfo.lastTaskTime
                    ? AirportJobInfo.lastTaskTime
                    : ""
                  }}
                </div>
              </div>
              <!-- <div class="list flex-a">
                <div class="listName">{{ t('73') }}</div>
                <div class="listContent">{{AirportJobInfo.latitude?AirportJobInfo.latitude:''}}</div>
              </div>
              <div class="list flex-a">
                <div class="listName">{{ t('74') }}</div>
                <div class="listContent">{{AirportJobInfo.longitude?AirportJobInfo.longitude:''}}</div>
              </div> -->
            </el-scrollbar>
          </div>
          <div class="Rheader flex-sb">
            <span>{{ t('1088') }}</span>
            <el-icon v-if="!flowEditShow" class="h_icon hover_opacity" @click="flowEdit">
              <EditPen />
            </el-icon>
            <div v-else class="flex-a">
              <div class="btn hover_opacity" @click="closeFlowEdit">{{ t('217') }}</div>
              <div class="btn btn_left hover_opacity" @click="submitFlowEdit">
                {{ t('284') }}
              </div>
            </div>
          </div>
          <div class="h_content">
            <div class="list flex-a">
              <div class="listName">RTMP</div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('822') }}</div>
              <div class="listContent" v-if="flowEditShow == false">
                {{ textHide(flowFlyList.liveFlight) }}
              </div>
              <div class="listContent" v-else>
                <input class="rolePut" onkeyup="this.value=this.value.replace(/\ +/g, '')"
                  v-model.trim="flowFlyList.liveFlight" :placeholder="t('1089')" />
              </div>
            </div>
            <div class="list flex-a">
              <div class="listName">FLV</div>
              <div class="listContent" v-if="flowEditShow == false">
                {{ flowFlyList.flvPort }}
              </div>
              <div class="listContent" v-else>
                <input class="rolePut" onkeyup="this.value=this.value.replace(/\ +/g, '')"
                  v-model.trim="flowFlyList.flvPort" :placeholder="t('1090')" />
              </div>
            </div>
            <div class="list flex-a">
              <div class="listName">RTC</div>
              <div class="listContent" v-if="flowEditShow == false">
                {{ flowFlyList.rtcPort }}
              </div>
              <div class="listContent" v-else>
                <input class="rolePut" onkeyup="this.value=this.value.replace(/\ +/g, '')"
                  v-model.trim="flowFlyList.rtcPort" :placeholder="t('1091')" />
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="dialogType == 'airport'">
          <div class="Rheader flex-sb">
            <span>{{ t('1092') }}</span>
            <el-icon v-if="!editShow" class="h_icon hover_opacity" @click="edit" v-checkAuth="'device-info-update'">
              <EditPen />
            </el-icon>
            <div v-else class="flex-a">
              <div class="btn hover_opacity" @click.self="editClose">{{ t('217') }}</div>
              <div class="btn btn_left hover_opacity" @click.self="editCommit">
                {{ t('284') }}
              </div>
            </div>
          </div>

          <div class="h_content">
            <div class="list flex-a">
              <div class="listName">{{ t('606') }}</div>
              <div class="listContent" v-if="editShow == false">
                {{ flyList.deviceName }}
              </div>
              <div class="listContent" v-else>
                <input class="rolePut" v-model="flyList.deviceName" onkeyup="this.value=this.value.replace(/\ +/g, '')"
                  :placeholder="t('372')" maxlength="20" />
              </div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('164') }}SN</div>
              <div class="listContent">{{ flyList.sn }}</div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('1065') }}</div>
              <div class="listContent">{{ flyList.model }}</div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('619') }}</div>
              <div class="listContent">{{ flyList.taskNumber }}</div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('412') }}</div>
              <div class="listContent">{{ flyList.createTime }}</div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('411') }}</div>
              <div class="listContent">{{ flyList.creater }}</div>
            </div>
            <div class="list flex-a relative_form">
              <div class="listName">{{ t('73') }}</div>
              <div class="listContent overflow_text" v-if="editShow === false" :title="flyList.lng">
                {{ flyList.lng }}
              </div>
              <div class="listContent" v-else>
                <input class="rolePut" v-model="flyList.lng" onkeyup="this.value=this.value.replace(/\ +/g, '')"
                  :placeholder="t('277')" @input="changeLng" @blur.prevent maxlength="32" />
                <span v-if="lngTure">{{ lngText }}</span>
              </div>
              <div class="position_div hover_opacity" v-show="editShow === true" @click="getPosition">
                <el-icon :size="18" class="element_icon" :class="{ rotate_icon: refreshing }" :color="'#0090DA'">
                  <Refresh />
                </el-icon>{{ t('1093') }}
              </div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('74') }}</div>
              <div class="listContent overflow_text" v-if="editShow === false" :title="flyList.lat">
                {{ flyList.lat }}
              </div>
              <div class="listContent" v-else>
                <input class="rolePut" v-model="flyList.lat" onkeyup="this.value=this.value.replace(/\ +/g, '')"
                  :placeholder="t('278')" @input="changeLat" @blur.prevent maxlength="32" />
                <span v-if="latTure">{{ latText }}</span>
              </div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('75') }}</div>
              <div class="listContent" v-show="editShow === false">
                {{ flyList.height }}m
              </div>
              <el-input-number v-show="editShow === true" class="el_input_number" size="small" :precision="2"
                v-model="flyList.height" @change="changeAirportModel"></el-input-number>
            </div>
            <div class="list flex-a">
              <div class="listName">安全飞行高度</div>
              <div class="listContent" v-show="editShow === false">
                {{ flyList.securityHeight }}m
              </div>
              <div class="listContent" v-show="editShow === true">
                <input class="rolePut" v-model="flyList.securityHeight"
                  onkeyup="this.value=this.value.replace(/\ +/g, '')" @blur="securityHeightChange" @input="changeSecurityHeigth"
                  placeholder="请输入安全飞行高度" maxlength="20" />
                  <span v-if="securityTure">{{ securityText }}</span>
              </div>
            </div>

            <div class="list flex-a">
              <div class="listName">{{ t('1094') }}</div>
              <div class="listContent" v-show="editShow === false">
                {{ flyList.direction }}°
              </div>
              <el-slider v-show="editShow === true" class="el_slider" v-model="flyList.direction" size="small" :min="0"
                :max="360" @change="changeAirportModel"></el-slider>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('618') }}</div>
              <div class="listContent" v-if="editShow == false">
                {{ flyList.number }}
              </div>
              <div class="listContent" v-else>
                <input class="rolePut" v-model="flyList.number" onkeyup="this.value=this.value.replace(/\ +/g, '')"
                  :placeholder="t('1063')" maxlength="20" @input="changeNumber" @onblur.prevent />
                <span class="" v-if="numberTrue">{{ numberText }}</span>
              </div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('1081') }}</div>
              <div class="listContent" v-if="editShow == false">
                {{ flyList.region }}
              </div>
              <el-cascader v-else ref="cityRef" class="common-input" style="width: 100%" :placeholder="t('213')"
                v-model="flyList.region" :options="cityList" :props="cityCasDer" @change="cityChange"
                popper-class="teamedit" />
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('214') }}</div>
              <div class="listContent" v-if="editShow == false">
                {{ flyList.teamName }}
              </div>
              <el-cascader v-else ref="teamRef" class="common-input" style="width: 100%" :show-all-levels="false"
                :placeholder="t('175')" v-model="flyList.teamId" :props="organizationCasDer" :options="teamList"
                @change="organiChange" popper-class="teamedit" />
            </div>
          </div>
          <!-- <div class="homeWork flex-sb">
            <span>{{ t('1082') }}</span>
            <el-icon class="h_icon hover_opacity"><Refresh /></el-icon>
          </div>
          <div class="h_content">
            <el-scrollbar>
              <div class="list flex-a" v-for="item in 6" :key="item">
                <div class="listName">{{ t('616') }}</div>
                <div class="listContent">{{ t('1083') }}</div>
                @click="getJobInfo(devsiceSn)"
              </div>
              <div class="list flex-a">
                <div class="listName">{{ t('1095') }}</div>
                <div class="listContent">
                  {{
                    AirportJobInfo.deviceVersion
                      ? AirportJobInfo.deviceVersion
                      : ""
                  }}
                </div>
              </div>
              <div class="list flex-a">
                <div class="listName">{{ t('1012') }}</div>
                <div class="listContent">
                  {{
                    AirportJobInfo.deviceModel ? AirportJobInfo.deviceModel : ""
                  }}
                </div>
              </div>
              <div class="list flex-a">
                <div class="listName">{{ t('1085') }}</div>
                <div class="listContent">
                  {{ AirportJobInfo.mountType ? AirportJobInfo.mountType : "" }}
                </div>
              </div>
              <div class="list flex-a">
                <div class="listName">{{ t('1086') }}</div>
                <div class="listContent">
                  {{
                    AirportJobInfo.lastTaskName
                      ? AirportJobInfo.lastTaskName
                      : ""
                  }}
                </div>
              </div>
              <div class="list flex-a">
                <div class="listName">{{ t('1087') }}</div>
                <div class="listContent">
                  {{
                    AirportJobInfo.lastTaskTime
                      ? AirportJobInfo.lastTaskTime
                      : ""
                  }}
                </div>
              </div>
              <div class="list flex-a">
                <div class="listName">{{ t('73') }}</div>
                <div class="listContent">{{AirportJobInfo.latitude?AirportJobInfo.latitude:''}}</div>
              </div>
              <div class="list flex-a">
                <div class="listName">{{ t('74') }}</div>
                <div class="listContent">{{AirportJobInfo.longitude?AirportJobInfo.longitude:''}}</div>
              </div>
            </el-scrollbar>
          </div> -->
          <!-- <div class="Rheader flex-sb">
            <span>{{ t('1088') }}</span>
            <el-icon
              v-if="!flowEditShow"
              class="h_icon hover_opacity"
              @click="flowEdit"
              ><EditPen
            /></el-icon>
            <div v-else class="flex-a">
              <div class="btn hover_opacity" @click="closeFlowEdit">{{ t('217') }}</div>
              <div class="btn btn_left hover_opacity" @click="submitFlowEdit">
                确定
              </div>
            </div>
          </div> -->
          <!-- <div class="h_content">
            <div class="list flex-a">
              <div class="listName">RTMP</div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('822') }}</div>
              <div class="listContent" v-if="flowEditShow == false">
                {{ textHide(flowAirportlist.liveFlight) }}
              </div>
              <div class="listContent" v-else>
                <input
                  class="rolePut"
                  onkeyup="this.value=this.value.replace(/\ +/g, '')"
                  v-model.trim="flowAirportlist.liveFlight"
                  :placeholder="t('1089')"
                />
              </div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('824') }}</div>
              <div class="listContent" v-if="flowEditShow == false">
                {{ textHide(flowAirportlist.liveIn) }}
              </div>
              <div class="listContent" v-else>
                <input
                  class="rolePut"
                  onkeyup="this.value=this.value.replace(/\ +/g, '')"
                  v-model.trim="flowAirportlist.liveIn"
                  :placeholder="t('1096')"
                />
              </div>
            </div>
            <div class="list flex-a">
              <div class="listName">{{ t('823') }}</div>
              <div class="listContent" v-if="flowEditShow == false">
                {{ textHide(flowAirportlist.liveOut) }}
              </div>
              <div class="listContent" v-else>
                <input
                  class="rolePut"
                  onkeyup="this.value=this.value.replace(/\ +/g, '')"
                  v-model.trim="flowAirportlist.liveOut"
                  :placeholder="t('1097')"
                />
              </div>
            </div>
            <div class="list flex-a">
              <div class="listName">FLV</div>
              <div class="listContent" v-if="flowEditShow == false">
                {{ flowAirportlist.flvPort }}
              </div>
              <div class="listContent" v-else>
                <input
                  class="rolePut"
                  onkeyup="this.value=this.value.replace(/\ +/g, '')"
                  v-model.trim="flowAirportlist.flvPort"
                  :placeholder="t('1090')"
                />
              </div>
            </div>
            <div class="list flex-a">
              <div class="listName">RTC</div>
              <div class="listContent" v-if="flowEditShow == false">
                {{ flowAirportlist.rtcPort }}
              </div>
              <div class="listContent" v-else>
                <input
                  class="rolePut"
                  onkeyup="this.value=this.value.replace(/\ +/g, '')"
                  v-model.trim="flowAirportlist.rtcPort"
                  :placeholder="t('1091')"
                />
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="otherbox" v-else>
    <div class="others">
      <div class="close flex-fe">
        <el-icon class="cicon" style="cursor: pointer" @click="closeDetail">
          <CloseBold />
        </el-icon>
      </div>
      <div class="Rheader flex-sb">
        <span>{{ t('1098') }}</span>
        <el-icon v-if="!editShow" class="h_icon hover_opacity" @click="edit" v-checkAuth="'device-info-update'">
          <EditPen />
        </el-icon>
        <div v-else class="flex-a">
          <div class="btn hover_opacity" @click.stop="editClose">{{ t('217') }}</div>
          <div class="btn btn_left hover_opacity" @click.stop="editCommit">
            {{ t('284') }}
          </div>
        </div>
      </div>
      <div class="h_content">
        <div class="list flex-a">
          <div class="listName">{{ t('616') }}</div>
          <div class="listContent" v-if="editShow == false">
            {{ otherList.accessoryName }}
          </div>
          <div class="listContent" v-else>
            <input class="rolePut" onkeyup="this.value=this.value.replace(/\ +/g, '')" v-model="otherList.accessoryName"
              :placeholder="t('668')" maxlength="20" />
          </div>
        </div>
        <div class="list flex-a">
          <div class="listName">{{ t('829') }}SN</div>
          <div class="listContent">{{ otherList.accessorySn }}</div>
        </div>
        <div class="list flex-a">
          <div class="listName">{{ t('1099') }}</div>
          <div class="listContent">{{ otherList.modelName }}</div>
        </div>
        <div class="list flex-a">
          <div class="listName">{{ t('412') }}</div>
          <div class="listContent">{{ otherList.createTime }}</div>
        </div>
        <div class="list flex-a">
          <div class="listName">{{ t('411') }}</div>
          <div class="listContent">{{ otherList.memberName }}</div>
        </div>
        <div class="list flex-a">
          <div class="listName">{{ t('618') }}</div>
          <div class="listContent" v-if="editShow == false">
            {{ otherList.accessoryNumber }}
          </div>
          <div class="listContent" v-else>
            <input class="rolePut" onkeyup="this.value=this.value.replace(/\ +/g, '')" v-model="otherList.accessoryNumber"
              :placeholder="t('1063')" maxlength="20" @input="changeNumber" @onblur.prevent />
            <span v-if="numberTrue">{{ numberText }}</span>
          </div>
        </div>
        <div class="list flex-a">
          <div class="listName">{{ t('626') }}</div>
          <div class="listContent" v-if="editShow == false">
            {{ otherList.deviceName }}
          </div>
          <div class="listContent" v-else>
            <el-select style="width: 100%" v-model="otherList.deviceSN" class="m-2 common-input"
              :placeholder="t('1068')" clearable>
              <!-- 
              @clear="cleanAirport" -->
              <el-option v-for="item in airportList" :key="item.id" :label="item.deviceName" :value="item.sn" />
            </el-select>
          </div>
        </div>
        <div class="list flex-a">
          <div class="listName">{{ t('1081') }}</div>
          <div class="listContent" v-if="editShow == false">
            {{ otherList.region }}
          </div>
          <el-cascader v-else ref="cityRef" class="rolePut common-input" style="width: 100%" :placeholder="t('213')"
            v-model="otherList.region" :options="cityList" :props="cityCasDer" @change="cityChange"
            popper-class="teamedit disabled_hide_cacader" />
          <!--  v-model="elForm.regionName" :options="city"
                :props="cityCasDer"
                @change="cityChange"-->
        </div>
        <div class="list flex-a">
          <div class="listName">{{ t('214') }}</div>
          <div class="listContent" v-if="editShow == false">
            {{ otherList.teamName }}
          </div>
          <el-cascader v-else ref="teamRef" class="rolePut common-input" style="width: 100%" :show-all-levels="false"
            :placeholder="t('175')" v-model="otherList.teamId" :props="organizationCasDer" :options="teamList"
            @change="organiChange" popper-class="teamedit disabled_hide_cacader" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { t } from '../../../languages';
import {
  defineComponent,
  inject,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
  watchEffect,
} from "vue";
import device from "../../../network/device";
import data from "../../../network/data";
import taskManage from "@/network/task";
import member from "../../../network/member";
import fence from "../../../network/fence";
import { ElMessage } from "element-plus";
import ufi3d from "@/utils/ufi-cesium";
import { store } from "@/store";
import GroundPoint from "../../../asset/img/routeLine/route_icon_light.png";
import { v4 as uuidv4 } from "uuid";
import * as turf from "@turf/turf";
import {
  findP,
  flatten,
  openRegionAdcode,
  findChildren,
} from "@/utils/openRegion";
import PlaneGraphics from "cesium/Source/DataSources/PlaneGraphics";
export default defineComponent({
  components: {},
  props: {
    editShow: {
      type: Boolean,
      default: () => { },
    },
    assets: {
      type: String,
      default: () => { },
    },
    dialogType: {
      type: String,
      default: () => { },
    },
    detailData: {
      type: Object,
      default: () => { },
    },
  },
  setup(props, { emit }) {
    let viewer = null;
    let handler = null;
    let measureDistance = null;
    let airportEntity = null;
    const cityRef = ref(null);
    const teamRef = ref(null);
    const Cesium = inject("Cesium");
    const { dialogType, detailData, editShow } = toRefs(props);
    const devsiceSn = ref("");
    devsiceSn.value = detailData.value.sn;
    const effect = async () => {
      if (dialogType.value == "asset") {
        getDeviceList();
        let res = await device.getAccessoryDetail(detailData.value.id);
        if (res.resultStatus) {
          state.otherList = res.resultData;
        }
      }
      if (dialogType.value == "fly" || dialogType.value == "airport") {
        let res = await device.getDeviceUav(detailData.value.id);
        if (res.resultStatus) {
          state.flyList = res.resultData;
          if (dialogType.value === "airport") {
            const data = await device.getAirportParam(state.flyList.sn);
            const flyData = await device.getFlyHeight(state.flyList.sn);
            if (data.resultStatus) {
              state.flyList.height = data.resultData.altitude
                ? data.resultData.altitude
                : 0;
              state.flyList.direction = data.resultData.direction
                ? data.resultData.direction
                : 90;
            }
            if (flyData.resultStatus) {
              state.flyList.securityHeight = flyData.resultData;
            }
            nextTick(() => {
              methods.createAirportModel(
                Number(state.flyList.lng),
                Number(state.flyList.lat),
                state.flyList.height,
                state.flyList.direction,
                state.flyList.modelType
              );
            });
          }
        }
      }
    };
    watchEffect(effect);

    watch(editShow, (newName) => {
      if (newName) {
        if (dialogType.value == "asset") {
          let res = state.otherList.region.split(";");
          state.otherList.region = res;
        }
        if (dialogType.value == "fly" || dialogType.value == "airport") {
          let res = state.flyList.region.split(";");
          state.flyList.region = res;
        }
      } else {
        if (dialogType.value == "asset") {
          let res = cityFormat(state.otherList.region);
          state.otherList.region = res;
        }
        if (dialogType.value == "fly" || dialogType.value == "airport") {
          let res = cityFormat(state.flyList.region);
          state.flyList.region = res;
        }
      }
    });
    fetch("json/area-tree.json")
      .then((d) => d.json())
      .then((res) => {
        const arr = store.state.system.openRegion;
        const arr1 = arr.map((item) => {
          return findP(item, res[0].children);
        });
        const treeAdcode = openRegionAdcode(res[0].children); //这个树的全部adcode
        const arr2 = flatten(arr1); //需要的  ,这里还去少区数据
        const arr3 = arr2.map((item) => {
          return findChildren(item, res[0].children); //补上缺少的区adcode
        });

        const needAdcode = Array.from(new Set([...arr2, ...flatten(arr3)]));
        const notAdcode = treeAdcode.filter(
          (v) => needAdcode.findIndex((el) => el === v) === -1
        ); //去重过滤需要的adcode
        const city = deepCopyIsOpenRegion(res[0].children); //添加禁用属性
        const needCity = delIsOpenRegion(city);

        state.cityList = needCity;

        // 递归遍历修改数据
        function deepCopyIsOpenRegion(source) {
          const target = []; //Array.isArray(source) ? [] : {}; //数组兼容
          for (const k in source) {
            if (typeof source[k] === "object") {
              target[k] = deepCopyIsOpenRegion(source[k]);
            } else {
              target[k] = source[k];
            }
          }
          if (notAdcode.length != 0) {
            notAdcode.map((item) => {
              if (target.adcode === item) {
                target.disabled = true;
              }
            });
          }
          return target;
        }

        // 去除禁用的数据
        function delIsOpenRegion(source) {
          const target = []; //Array.isArray(source) ? [] : {}; //数组兼容
          source.map((item) => {
            if (item.disabled != true) {
              target.push(item);
            }
          });
          return target;
        }
      });
    const state = reactive({
      otherList: {
        createTime: "",
        memberName: "",
        accessoryName: "",
        accessoryNumber: "",
        accessorySn: "",
        id: "",
        modelId: "",
        modelName: "",
        deviceSN: "",
        deviceName: "",
        region: "",
        teamId: "",
        teamName: "",
      },
      flyList: {
        createTime: "",
        creater: "",
        deviceName: "",
        id: "",
        model: "",
        number: "",
        region: "",
        sn: "",
        taskNumber: "",
        lat: "",
        lng: "",
        teamId: "",
        teamName: "",
        height: 0,
        securityHeight: 20,
        direction: 90,
      },
      cityList: [],
      cityVal: "", //city选中值
      teamList: [],
      teamVal: "", //团队
      // 修改cascader默认配置
      cityCasDer: {
        value: "name",
        label: "title",
        checkStrictly: true,
        children: "children",
        expandTrigger: "click",
      },
      organizationCasDer: {
        value: "id",
        label: "teamName",
        checkStrictly: true,
        children: "children",
        expandTrigger: "click",
      },

      flowEditShow: false,
      //无人机推流基本信息
      flowFlyList: {
        id: "",
        liveFlight: "",
        liveIn: "",
        liveOut: "",
        flvPort: "",
        rtcPort: "",
        sn: detailData.value.sn,
      },
      //机场推流基本信息
      flowAirportlist: {
        id: "",
        liveFlight: "",
        liveIn: "",
        liveOut: "",
        flvPort: "",
        rtcPort: "",
        sn: detailData.value.sn,
      },
      layarList: [], //图层列表
      filterLayerList: [], //图层筛选列表
      UavJobInfo: {}, //无人机
      AirportJobInfo: {
        deviceId: "",
        deviceModel: "",
        deviceVersion: "",
        lastTaskName: "",
        lastTaskTime: "",
        latitude: "",
        longitude: "",
        mountType: "",
      }, //机场作业信息
      airportList: []
    });
    onMounted(() => {
      methods.teamData();
      methods.getAllLayers();
      if (dialogType.value != "asset") {
        getJobInfo(detailData.value.sn);
        getFenceListData();
      }
      // methods.getLiveBySn(detailData.value.sn);
      nextTick(() => {
        if (dialogType.value == "asset") return;
        methods.initEarth();
      });
    });
    onBeforeUnmount(() => {
      if (dialogType.value == "asset") return;
      methods.destoryEearth();
    });
    const cityFormat = function (e) {
      let city = "";
      if (e.length == 1) {
        city = `${e[0]}`;
      } else if (e.length == 2) {
        city = `${e[0]};${e[1]}`;
      } else if (e.length == 3) {
        city = `${e[0]};${e[1]};${e[2]}`;
      }
      return city;
    };
    const methods = reactive({
      //所属团队
      teamData: async () => {
        let res = await member.getTreeList();
        state.teamList = res.resultData;
      },
      // 关闭详情
      closeDetail: () => {
        emit("closeDetail");
      },
      // 编辑
      edit: () => {
        emit("edit");
      },
      // 取消编辑
      editClose: () => {
        emit("editClose");
        effect();
      },
      // 提交编辑
      editCommit: async () => {
        if (numberTrue.value || latTure.value || lngTure.value ||securityTure.value) return;
        let edit = {};
        if (dialogType.value == "asset") {
          edit.id = state.otherList.id;
          edit.accessoryName = state.otherList.accessoryName;
          edit.accessoryNumber = state.otherList.accessoryNumber;
          edit.deviceSN = state.otherList.deviceSN;
          edit.modelId = state.otherList.modelId;
          edit.accessorySn = state.otherList.accessorySn;
          let city = cityFormat(state.otherList.region);
          edit.region = city;
          let teamId = "";
          if (typeof state.otherList.teamId == "object") {
            let team = state.otherList.teamId.length - 1;
            teamId = state.otherList.teamId[team];
          } else {
            teamId = state.otherList.teamId;
          }
          edit.teamId = teamId;
          let res = await device.editAccessory(edit);
          if (res.resultStatus) {
            ElMessage.success(t("714"));
            emit("editCommit");
            effect();
          }
        }
        if (dialogType.value == "fly" || dialogType.value == "airport") {
          edit.id = state.flyList.id;
          edit.deviceName = state.flyList.deviceName;
          edit.number = state.flyList.number;
          let city = cityFormat(state.flyList.region);
          edit.region = city;
          let teamId = "";
          if (typeof state.flyList.teamId == "object") {
            let team = state.flyList.teamId.length - 1;
            teamId = state.flyList.teamId[team];
          } else {
            teamId = state.flyList.teamId;
          }
          edit.teamId = teamId;
          edit.lat = state.flyList.lat;
          edit.lng = state.flyList.lng;
          if (dialogType.value == "airport") {
            device
              .updateAirportParam({
                altitude: state.flyList.height,
                direction: state.flyList.direction,
                sn: state.flyList.sn,
              })
              .then(() => { });
            device
              .editFlyHeight(Number(state.flyList.securityHeight), state.flyList.sn)
              .then(() => { });
          }
          let res = await device.editDevice(edit);
          if (res.resultStatus) {
            ElMessage.success(t("714"));
            emit("editCommit");
            effect();
          }
        }
      },
      // 获取推流信息
      getLiveBySn: async (sn) => {
        let res = await device.getLiveBySn(sn);
        if (res.resultStatus) {
          if (dialogType.value == "fly") {
            if (res.resultData == null) return;
            const { id, liveFlight, flvPort, rtcPort } = res.resultData;
            state.flowFlyList.id = id ? id : "";
            state.flowFlyList.liveFlight = liveFlight;
            state.flowFlyList.flvPort = flvPort;
            state.flowFlyList.rtcPort = rtcPort;
            // state.flowFlyList.sn=detailData.value.sn;
          }
          if (dialogType.value == "airport") {
            if (res.resultData == null) return;
            const { id, liveFlight, liveIn, liveOut, flvPort, rtcPort } =
              res.resultData;
            state.flowAirportlist.id = id ? id : "";
            state.flowAirportlist.liveFlight = liveFlight;
            state.flowAirportlist.liveIn = liveIn;
            state.flowAirportlist.liveOut = liveOut;
            state.flowAirportlist.flvPort = flvPort;
            state.flowAirportlist.rtcPort = rtcPort;
            // state.flowAirportlist.sn=detailData.value.sn;
          }
        }
      },
      // 推流编辑
      flowEdit: () => {
        state.flowEditShow = !state.flowEditShow;
      },
      // 取消推流编辑
      closeFlowEdit: () => {
        state.flowEditShow = false;
        methods.getLiveBySn(detailData.value.sn);
      },
      // 保存推流编辑
      submitFlowEdit: () => {
        let obj =
          dialogType.value == "fly" ? state.flowFlyList : state.flowAirportlist;
        device.OperateLive(obj).then((res) => {
          if (res.resultStatus) {
            ElMessage.success(t("714"));
            state.flowEditShow = false;
            methods.getLiveBySn(detailData.value.sn);
          }
        });
      },
      // 选中地区
      cityChange: () => {
        cityRef.value.togglePopperVisible();
      },
      // 组织
      organiChange: () => {
        teamRef.value.togglePopperVisible();
      },
      // 初始化地球
      initEarth: () => {
        Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
          90.0, // 东
          30.0, // 南
          110.0, // 西
          45.0 // 北
        );
        const tiandituConSess = localStorage.getItem("tiandituCon");
        viewer = ufi3d.initCesium("cesiumContainer", {
          // 地形
          terrainProvider: setTerrain(),
        });
        /* 设置地形 */
        function setTerrain() {
          const mars3dTerrain = localStorage.getItem('mars3dTerrain')
          const terrainUrl = (mars3dTerrain == 'true') ? 'https://data.mars3d.cn/terrain' : window.g.terrainUrl
          return new Cesium.CesiumTerrainProvider({
            url: terrainUrl
          })
        };
        if (tiandituConSess === "true") {
          // 天地图
          const subdomains = ["0", "1", "2", "3", "4", "5", "6", "7"];
          const layers = viewer.imageryLayers;
          layers.addImageryProvider(
            new Cesium.WebMapTileServiceImageryProvider({
              //影像底图
              url:
                "https://t{s}.tianditu.gov.cn/img_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=img&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=" +
                "47421dd0051ada3957f279b484932789",
              subdomains: subdomains, //URL模板中用于{s}占位符的子域。如果该参数是单个字符串，则字符串中的每个字符都是一个子域。如果它是一个数组，数组中的每个元素都是一个子域
              layer: "tdtImgLayer",
              style: "default",
              format: "image/jpeg",
              tileMatrixSetID: "GoogleMapsCompatible", //使用谷歌的瓦片切片方式
              maximumLevel: 18,
              show: true,
            })
          );
          layers.addImageryProvider(
            new Cesium.WebMapTileServiceImageryProvider({
              //影像注记
              url:
                "https://t{s}.tianditu.gov.cn/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=" +
                "47421dd0051ada3957f279b484932789",
              subdomains: subdomains,
              layer: "tdtCiaLayer",
              style: "default",
              format: "image/jpeg",
              tileMatrixSetID: "GoogleMapsCompatible",
              maximumLevel: 18,
              show: true,
            })
          );
        } else {
          // 谷歌地图
          const layers = viewer.imageryLayers;
          layers.addImageryProvider(
            new Cesium.UrlTemplateImageryProvider({
              url: window.g.satelliteMapUrl + "/{z}/{x}/{y}.png",
              tilingScheme: new Cesium.WebMercatorTilingScheme(),
            })
          );
        }
        handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      },
      destoryEearth: () => {
        viewer.entities.removeAll();
        viewer = null;
        document.querySelector("#cesiumContainer").innerHTML = "";
      },
      getModelByType: (type) => {
        let modelUrl = "";
        let scale = 1;
        switch (type) {
          case "XNest 3 - E":
            // 御3充电
            modelUrl = "x3chong.glb";
            scale = 0.000001;
            break;
          case "XNest 3Plus - E":
            // 御3换电
            modelUrl = "x3huan.glb";
            scale = 0.000001;
            break;
          case "XNest 2":
          // 御2平开
          case "XNest 2Plus - E":
            // 御2换电平开
            modelUrl = "x2ping.glb";
            scale = 0.000001;
            break;
          default:
            modelUrl = "airport.glb";
            scale = 1;
            break;
        }
        return {
          modelUrl,
          scale,
        };
      },
      /* 创建机场模型 */
      createAirportModel: (lng, lat, height, direction, modelType) => {
        if (viewer) {
          if (airportEntity) {
            methods.changeAirportModel();
          } else {
            const position = Cesium.Cartesian3.fromDegrees(lng, lat, height);
            const { modelUrl, scale } = methods.getModelByType(modelType);
            airportEntity = viewer.entities.add({
              position: position,
              orientation: Cesium.Transforms.headingPitchRollQuaternion(
                position,
                new Cesium.HeadingPitchRoll(
                  Cesium.Math.toRadians(direction), // 设置这个属性即可（顺时针旋转的角度值）
                  Cesium.Math.toRadians(0),
                  Cesium.Math.toRadians(0)
                )
              ),
              model: {
                uri: "public/model/" + modelUrl,
                scale: scale,
              },
            });
            ufi3d.viewerFlyTo(viewer, {
              entity: airportEntity,
              heading: 0,
              pitch: -45,
              range: 20,
              duration: 2,
            });
          }
        }
      },
      // 修改机场模型
      changeAirportModel() {
        const position = Cesium.Cartesian3.fromDegrees(
          Number(state.flyList.lng),
          Number(state.flyList.lat),
          Number(state.flyList.height)
        );
        airportEntity.position = position;
        airportEntity.orientation =
          Cesium.Transforms.headingPitchRollQuaternion(
            position,
            new Cesium.HeadingPitchRoll(
              Cesium.Math.toRadians(Number(state.flyList.direction)), // 设置这个属性即可（顺时针旋转的角度值）
              Cesium.Math.toRadians(0),
              Cesium.Math.toRadians(0)
            )
          );
      },
      // 获取所有图层
      getAllLayers: async () => {
        const res = await data.getAllLayers();
        if (res.resultStatus) {
          const layarData = res.resultData;
          for (let i = 0; i < layarData.length; i++) {
            const item = layarData[i];
            item.displayStatus = false;
          }
          state.layarList = layarData;
          state.filterLayerList = state.layarList;
        }
      },
    });
    const refreshing = ref(false); //获取定位状态，用于更新定位图标状态
    /* 获取机场位置 */
    async function getPosition() {
      refreshing.value = true;
      setTimeout(() => {
        refreshing.value = false;
      }, 1000);
      const data = await device.refreshPosition(state.flyList.sn);
      if (data.resultStatus === true) {
        state.flyList.lng = data.resultData.longitude;
        state.flyList.lat = data.resultData.latitude;
        ElMessage.success(t("1100"));
      }
    }
    /**
     * 文本超出拼接
     * @param {* 文本内容} text
     */
    function textHide(text) {
      if (text.length > 36) {
        return text.slice(0, 35) + "...";
      }
    }
    // 安全高度失焦事件
    function securityHeightChange() {
      if(state.flyList.securityHeight>1500){
        state.flyList.securityHeight = 1500;
        securityTure.value = false;
      }
      if(state.flyList.securityHeight<20){
        state.flyList.securityHeight = 20;
        securityTure.value = false;
      }
    }
   

    // 机场作业信息
    async function getJobInfo(sn) {
      let res = await device.getJobInfo(sn);
      if (res.resultStatus) {
        state.AirportJobInfo = res.resultData;
      }
    }
    // 配件详情机场下拉列表
    async function getDeviceList() {
      const res = await taskManage.getDeviceList({
        deviceType: "AIRPORT",
      });
      if (res.resultStatus) {
        state.airportList = res.resultData;
      }
    };

    let customImagery = null; //tif影像对象
    /**
     * 添加影像图
     * @param {* 影像图id} id
     * @param {* 影像图地址} addr
     * @param {* 影像图元信息} tifInfo
     */
    function addImagery(id, addr, tifInfo, isZoomTo) {
      if (!customImagery) {
        customImagery = new ufi3d.CustomImagery(viewer);
      }
      customImagery.addImagery(id, addr, tifInfo, isZoomTo);
    }

    /**
     * 切换影像图显示方式
     * @param {* 影像图id} id
     * @param {* 是否显示} flag
     */
    function toggleImagery(id, flag) {
      customImagery.showHideImagery(id, flag);
    }

    /**
     * 定位影像图
     * @param {* 影像图id} id
     */
    function zoomToImagery(id) {
      customImagery.zoomToImagery(id);
    }

    let pointCloud = null; //点云对象
    /**
     * 添加图层
     * @param {* 图层id} id
     * @param {* 图层地址} addr
     * @param {* 是否定位} displayFlag
     */
    function add3DTiles(id, addr, displayFlag) {
      if (!pointCloud) {
        pointCloud = new ufi3d.PointCloud(viewer);
      }
      pointCloud.addPointCloud(id, addr, 0, displayFlag);
    }

    /**
     * 切换图层显示方式
     * @param {* 图层id} id
     * @param {* 是否显示} flag
     */
    function toggle3DTiles(id, flag) {
      pointCloud.showHidePointCloud(id, flag);
    }

    /**
     * 定位影像图
     * @param {* 图层id} id
     */
    function zoomTo3DTiles(id) {
      pointCloud.jumpCloudPoint(id);
    }

    const visibleLayar = []; //已经实例化过的图层列表
    /* 切换图层显示 */
    function changeLayarVisible(item) {
      const layerId = item.layerId;
      const isCheck = item.displayStatus;
      const layerUrl = item.layerUrl;
      const layerType = item.layerType;
      if (visibleLayar.includes(layerId)) {
        // 选中过
        if (layerType === "TIF") {
          toggleImagery(layerId, isCheck);
        } else {
          toggle3DTiles(layerId, isCheck);
        }
      } else {
        // 没有选中过
        visibleLayar.push(layerId);
        // 初始化点云、b3dms或tif
        if (layerType === "TIF") {
          addImagery(layerId, layerUrl, item.tifMetaInfo, true);
        } else {
          add3DTiles(layerId, layerUrl, true);
        }
      }
    }

    /* 定位图层 */
    function zoomToLayer(item) {
      const layerId = item.layerId;
      if (visibleLayar.includes(layerId)) {
        if (item.layerType === "TIF") {
          zoomToImagery(layerId);
        } else {
          zoomTo3DTiles(layerId);
        }
      } else {
        ElMessage.warning(t("148"));
      }
    }

    const layerFilterWord = ref(""); //筛选图层关键词
    /* 筛选图层列表 */
    function filterLayer() {
      state.filterLayerList = state.layarList.filter((item) => {
        if (item.layerName.indexOf(layerFilterWord.value) !== -1) {
          return item;
        }
      });
    }

    /* 筛选图层输入框改变 */
    function layerChange() {
      if (layerFilterWord.value === "") {
        state.filterLayerList = state.layarList;
      }
    }

    // 经纬度校验
    const latText = ref(t("1101"));
    const latTure = ref(false);
    function changeLat(e) {
      let reg = /^-?(?:90(?:\.0{1,5})?|(?:[1-8]?\d(?:\.\d{1,32})?))$/;
      let isPoint = e.target.value.substr(0, 1);
      if (isNaN(e.target.value)) {
        latText.value = t("1101");
        latTure.value = true;
      } else if (isPoint === ".") {
        latText.value = t("1102");
        latTure.value = true;
      } else if (e.target.value === "") {
        latText.value = t("278");
        latTure.value = true;
      } else if (!reg.test(e.target.value)) {
        latText.value = t("1103");
        latTure.value = true;
      } else {
        latTure.value = false;
      }
    }

    const lngText = ref(t("1101"));
    const lngTure = ref(false);
    function changeLng(e) {
      let reg =
        /^-?(?:(?:180(?:\.0{1,5})?)|(?:(?:(?:1[0-7]\d)|(?:[1-9]?\d))(?:\.\d{1,32})?))$/;
      let isPoint = e.target.value.substr(0, 1);
      if (isNaN(e.target.value)) {
        lngText.value = t("1101");
        lngTure.value = true;
      } else if (isPoint === ".") {
        lngText.value = t("1102");
        lngTure.value = true;
      } else if (e.target.value === "") {
        lngText.value = t("277");
        lngTure.value = true;
      } else if (!reg.test(e.target.value)) {
        lngText.value = t("1104");
        lngTure.value = true;
      } else {
        lngTure.value = false;
      }
    }
    // 安全飞行高度校验
    const securityText = ref(t("只允许输入20-1500的整数"));
    const securityTure = ref(false)
    function changeSecurityHeigth(e){
      let reg = /^(20|[1-9]\d|[1-9]\d{2}|1[0-4]\d{2}|1500)$/
      if (isNaN(e.target.value)) { 
        securityText.value = t("只允许输入20-1500的整数");
        securityTure.value = true;
      } else if (e.target.value == "") {
        securityText.value = t("请输入安全飞行高度");
        securityTure.value = true;
      } else if (!reg.test(e.target.value)) {
        securityText.value = t("只允许输入20-1500的整数");
        securityTure.value = true;
      } else {
        securityTure.value = false;
      }
    }
    // 编号校验
    const numberText = ref(t("1070"));
    const numberTrue = ref(false);
    function changeNumber(e) {
      let rexp = /[\u4E00-\u9FA5]/;
      if (rexp.test(e.target.value)) {
        numberTrue.value = true;
      } else {
        numberTrue.value = false;
      }
    }
    /**
     * 区域js逻辑  statrt
     */
    //获取区域列表
    async function getFenceListData() {
      let resFence = await fence.getFenceList({
        sn: detailData.value.sn,
      });
      if (resFence.resultStatus) {
        yesFlyList.value = resFence.resultData[1];
        noFlyList.value = resFence.resultData[2];
        heightLimitList.value = resFence.resultData[3];
        if (Array.isArray(noFlyList.value) && noFlyList.value.length !== 0) {
          noFlyList.value.forEach(e => {
            if (e.visible) {
              changeNoFly(e);
            }
          })
        }
        if (Array.isArray(heightLimitList.value) && heightLimitList.value.length !== 0) {
          heightLimitList.value.forEach(e => {
            if (e.visible) {
              changeHeightLimit(e);
            }
          })
        }
      }
    }
    const checkAll = ref(false);
    const isIndeterminate = ref(true);
    const handleCheckAllChange = (val) => {
      if (val) {
        noFlyList.value.forEach((e) => {
          if (e.visible) {
            e.visible = false;
            changeNoFly(e);
          }
          e.visible = true;
          changeNoFly(e);
        });
      } else {
        noFlyList.value.forEach((e) => {
          e.visible = false;
          changeNoFly(e);
        });
      }
      isIndeterminate.value = false;
    };
    const checkAllHeightLimit = ref(false);
    const isIndeterminateHeightLimit = ref(true);
    const handleCheckAllChangeHeightLimit = (val) => {
      if (val) {
        heightLimitList.value.forEach((e) => {
          if (e.visible) {
            e.visible = false;
            changeHeightLimit(e);
          }
          e.visible = true;
          changeHeightLimit(e);
        });
      } else {
        heightLimitList.value.forEach((e) => {
          e.visible = false;
          changeHeightLimit(e);
        });
      }
      isIndeterminateHeightLimit.value = false;
    };
    const checkAllYesFly = ref(false);
    const handleCheckAllChangeYesFly = async (val) => {
      if (val) {
        if (yesFlyList.value.length === 0) {
          ElMessage.error(t("590"));
          checkAllYesFly.value = false;
          return;
        } else {
          const data = await device.refreshPosition(state.flyList.sn);
          if (data.resultStatus) {
            createYesFlyCylinder(
              data.resultData.longitude,
              data.resultData.latitude,
              yesFlyList.value[0].coordinateObj.radius,
              yesFlyList.value[0].coordinateObj.height,
              yesFlyList.value[0].coordinateObj.interval,
              false
            );
          }
        }
      } else {
        if (yesFlyList.value.length !== 0) {
          viewer.entities.removeById("yes_fly");
        }
      }
    };
    const checkAllGird = ref(false);
    const handleCheckAllChangeGird = async (val) => {
      if (val) {
        const data = await device.refreshPosition(state.flyList.sn);
        var ellipsoid = viewer.scene.globe.ellipsoid;
        var cartographic = Cesium.Cartographic.fromDegrees(
          data.resultData.longitude,
          data.resultData.latitude,
          0
        );
        var cartesian3 = ellipsoid.cartographicToCartesian(cartographic);
        let newRPosition = getNorthPointByDistance(
          cartesian3,
          yesFlyList.value[0].coordinateObj.radius,
          "y"
        );
        //正方形右上角坐标
        const newRPositionX = getNorthPointByDistance(
          newRPosition,
          yesFlyList.value[0].coordinateObj.radius,
          "x"
        );
        const cartographicX = ellipsoid.cartesianToCartographic(newRPositionX);
        const lngX = Cesium.Math.toDegrees(cartographicX.longitude); //右上角经度
        const latX = Cesium.Math.toDegrees(cartographicX.latitude); //右上角纬度
        //正方形左上角坐标
        const newRPositionWest = getNorthPointByDistance(
          newRPosition,
          -yesFlyList.value[0].coordinateObj.radius,
          "x"
        );
        const cartographicWest =
          ellipsoid.cartesianToCartographic(newRPositionWest);
        const lngWest = Cesium.Math.toDegrees(cartographicWest.longitude); //左上角经度
        const latWest = Cesium.Math.toDegrees(cartographicWest.latitude); //左上角纬度

        //正方形右下角坐标
        const newRPositionSorth = getNorthPointByDistance(
          newRPositionX,
          -(yesFlyList.value[0].coordinateObj.radius * 2),
          "y"
        );
        //正方形左下角坐标
        const newRPositionNorth = getNorthPointByDistance(
          newRPositionWest,
          -(yesFlyList.value[0].coordinateObj.radius * 2),
          "y"
        );
        const cartographicNorth = Cesium.Cartographic.fromCartesian(
          newRPositionNorth,
          viewer.scene.globe.ellipsoid
        );
        const lngNorth = Cesium.Math.toDegrees(cartographicNorth.longitude); //左下角经度
        const latNorth = Cesium.Math.toDegrees(cartographicNorth.latitude); //左下角纬度
        const latTureM = 0.00000899; //纬度（南北方向）1M实际度
        let tempLng = yesFlyList.value[0].coordinateObj.interval * latTureM;
        spaceLng.value = Number(tempLng.toFixed(4));
        let cartographicWestc =
          ellipsoid.cartesianToCartographic(newRPositionWest);
        let cartographicWesty = Cesium.Math.toDegrees(
          cartographicWestc.latitude
        );
        let cartographicNorthc =
          ellipsoid.cartesianToCartographic(newRPositionNorth);
        let cartographicNorthy = Cesium.Math.toDegrees(
          cartographicNorthc.latitude
        );
        let lngAllLong = cartographicWesty - cartographicNorthy;
        //分成了多少份
        allSpaceShare.value = Math.ceil(lngAllLong / spaceLng.value);
        createYesFlyGrid(
          data,
          lngWest,
          lngNorth,
          latWest,
          latX,
          lngX,
          latNorth
        );
      } else {
        if (entityGird.value.length !== 0) {
          // 删除所有的点实体
          entityGird.value.forEach((item) => {
            viewer.entities.remove(item);
          });
          entityGird.value = [];
          return;
        }
      }
    };
    function getNorthPointByDistance(position, distance, data) {
      //以点为原点建立局部坐标系（东方向为x轴,北方向为y轴,垂直于地面为z轴），得到一个局部坐标到世界坐标转换的变换矩阵
      var localToWorld_Matrix =
        Cesium.Transforms.eastNorthUpToFixedFrame(position);
      if (data === "y") {
        return Cesium.Matrix4.multiplyByPoint(
          localToWorld_Matrix,
          Cesium.Cartesian3.fromElements(0, distance, 0),
          new Cesium.Cartesian3()
        );
      } else if (data === "x") {
        return Cesium.Matrix4.multiplyByPoint(
          localToWorld_Matrix,
          Cesium.Cartesian3.fromElements(distance, 0, 0),
          new Cesium.Cartesian3()
        );
      } else if (data === "z") {
        return Cesium.Matrix4.multiplyByPoint(
          localToWorld_Matrix,
          Cesium.Cartesian3.fromElements(0, 0, distance),
          new Cesium.Cartesian3()
        );
      }
    }

    const no_fly_div_show = ref(false);
    function openNoFlyShow() {
      no_fly_div_show.value = !no_fly_div_show.value;
    }
    const add_no_flv_div_show = ref(false);
    const noFlyPointList = ref([]); //添加禁飞区的点集合
    const noFlyList = ref([]); //禁飞区数据集合
    const isHeightLimitShowHeight = ref(false); //是否显示限高区高度
    //打开添加禁飞区\限高区
    function addNoFlyRegion(data) {
      if (data === 0) {
        isHeightLimitShowHeight.value = false;
      } else if (data === 1) {
        isHeightLimitShowHeight.value = true;
      }
      add_no_flv_div_show.value = !add_no_flv_div_show.value;
      // draw(); //注册鼠标右击事件
      drawPolygon1(true);
      // 十字样式
      setCursorStyle("crosshair");
    }
    //删除禁飞区/限高区生成的点
    function delNoFlyPoint(data) {
      deltePoint(data);
    }
    //
    function changeNoFly(data) {
      if (data.visible) {
        createNoFlyCylinder(data, 500, "red");
      } else {
        delNoFlyCylinder(data);
      }
    }
    //删除禁飞区
    async function delNoFlyRegion(data) {
      let res = await fence.deleteFence(data.regionId);
      if (res.resultStatus) {
        noFlyList.value = noFlyList.value.filter(
          (item) => item.regionId != data.regionId
        );
        delNoFlyCylinder(data);
        ElMessage.success(t("560"));
      }
    }
    const noFlyName = ref(""); //禁飞区/限高区名称
    async function sureSaveNoFlyRegion(data) {
      //保存禁飞区、限高区数据
      if (data) {
        if (
          heightLimitRegionHeight.value === "" ||
          heightLimitRegionHeight.value === null
        ) {
          ElMessage.error(t("1105"));
          return;
        }
        if (
          heightLimitRegionHeight.value < 100 ||
          heightLimitRegionHeight.value > 10000
        ) {
          ElMessage.error(t("1106"));
          return;
        }
      }
      if (noFlyPointList.value.length < 3) {
        ElMessage.error(t("1107"));
        return;
      }
      if (noFlyName.value === "" || noFlyName.value === null) {
        ElMessage.error(t("1108"));
        return;
      }
      const regionId = uuidv4(); //区域的id
      let obj = {};
      let pointsArr = [];
      obj.fenceName = noFlyName.value;
      obj.regionId = regionId;
      obj.visible = true;
      var ellipsoid = viewer.scene.globe.ellipsoid;
      noFlyPointList.value.forEach((e) => {
        let cartographic = ellipsoid.cartesianToCartographic(e.position);
        let pointsObj = {};
        pointsObj.lng = Cesium.Math.toDegrees(cartographic.longitude);
        pointsObj.lat = Cesium.Math.toDegrees(cartographic.latitude);
        pointsObj.alt = cartographic.height;
        pointsArr.push(pointsObj);
      });
      obj.coordinateDTOS = pointsArr;
      let newObj = {};
      newObj.fenceName = noFlyName.value;
      newObj.regionId = regionId;
      newObj.visible = true;
      newObj.coordinateObj = pointsArr;
      // obj.name = noFlyName.value;
      // obj.points = noFlyPointList.value;
      // obj.regionId = regionId;
      // obj.displayStatus = false;
      // 如果是限高区
      if (data) {
        obj.height = heightLimitRegionHeight.value;
        newObj.height = heightLimitRegionHeight.value;
        let res = await fence.addHeightLimit(obj);
        if (res.resultStatus) {
          ElMessage.success(t("1109"));
          //获取限高区数据
          heightLimitList.value.push(newObj);
          createNoFlyCylinder(newObj, heightLimitRegionHeight.value, "yellow");
        }
      } else {
        // 如果是禁飞区
        let res = await fence.addFlyLimit(obj);
        if (res.resultStatus) {
          ElMessage.success(t("1110"));
          newObj.height = null;
          //获取禁飞区数据
          noFlyList.value.push(newObj);
          createNoFlyCylinder(newObj, 500, "red");
        }
      }
      //做请求
      //清空添加禁飞区时生成点的实体
      noFlyPointList.value.forEach((e) => {
        viewer.entities.removeById(e.pointId);
      });
      add_no_flv_div_show.value = false;
      noFlyPointList.value = []; //清空添加禁飞区时生成的点集合
      noFlyName.value = ""; //清空添加禁飞区时禁飞区的名称
      heightLimitRegionHeight.value = ""; //清空限高区高度
      // 默认样式
      setCursorStyle("default");
      clearAll();
    }

    function cancelSaveNoFlyRegion(data) {
      //取消保存禁飞区、限高区数据
      if (noFlyPointList.value.length !== 0) {
        //清空添加禁飞区时生成点的实体
        noFlyPointList.value.forEach((e) => {
          viewer.entities.removeById(e.pointId);
        });
      }
      add_no_flv_div_show.value = false;
      noFlyPointList.value = []; //清空添加禁飞区时生成的点集合
      noFlyName.value = ""; //清空添加禁飞区时禁飞区的名称
      // 默认样式
      setCursorStyle("default");
      clearAll();
    }
    //限高
    const heightLimitList = ref([]);
    const height_limit_div_show = ref(false);
    function openHeightLimitShow() {
      height_limit_div_show.value = !height_limit_div_show.value;
    }
    const heightLimitRegionHeight = ref(""); //限高区高度
    function changeHeightLimit(data) {
      if (data.visible) {
        createNoFlyCylinder(data, data.height, "yellow");
      } else {
        delNoFlyCylinder(data);
      }
    }
    //删除限高区
    async function delHeightLimitRegion(data) {
      let res = await fence.deleteFence(data.regionId);
      if (res.resultStatus) {
        heightLimitList.value = heightLimitList.value.filter(
          (item) => item.regionId != data.regionId
        );
        delNoFlyCylinder(data);
        ElMessage.success(t("560"));
      }
    }
    //可飞
    const yes_fly_div_show = ref(false);
    const yesFlyList = ref([]); //可飞区列表
    const yesFlynHeight = ref("1222"); //可飞区高度
    const yesFlyGird = ref("122"); //可飞区网格
    const entityGird = ref([]); //可飞区网格实体
    const yesFlyRadius = ref("1222"); //可飞区半径
    function openYesFlyShow() {
      yes_fly_div_show.value = !yes_fly_div_show.value;
    }
    //操作可飞区
    const handleYesFlyERegionShow = ref(false); //可飞区添加面板
    function handleYesFlyERegion(data) {
      handleYesFlyERegionShow.value = !handleYesFlyERegionShow.value;
      add_no_flv_div_show.value = true;
      if (data === "edit") {
        yesFlyRadius.value = yesFlyList.value[0].coordinateObj.radius;
        yesFlynHeight.value = yesFlyList.value[0].coordinateObj.height;
        yesFlyGird.value = yesFlyList.value[0].coordinateObj.interval;
      }
    }
    //保存可飞区
    const spaceLng = ref(0); //一个格子的经度
    const allSpaceShare = ref(0); //分成多少份
    async function sureSaveYesFlyRegion() {
      const lngTureM = 0.00001141; //经度（东西方向）1M实际度
      const latTureM = 0.00000899; //纬度（南北方向）1M实际度
      if (yesFlyRadius.value === "" || yesFlyRadius.value === null) {
        ElMessage.error(t("1111"));
        return;
      }
      if (yesFlynHeight.value === "" || yesFlynHeight.value === null) {
        ElMessage.error(t("1105"));
        return;
      }
      if (yesFlyGird.value === "" || yesFlyGird.value === null) {
        ElMessage.error(t("1112"));
        return;
      }
      //判断网格间隔是否合理
      const data = await device.refreshPosition(state.flyList.sn);
      var ellipsoid = viewer.scene.globe.ellipsoid;
      var cartographic = Cesium.Cartographic.fromDegrees(
        data.resultData.longitude,
        data.resultData.latitude,
        0
      );
      var cartesian3 = ellipsoid.cartographicToCartesian(cartographic); //机场世界坐标
      let newRPosition = getNorthPointByDistance(
        cartesian3,
        yesFlyRadius.value,
        "y"
      );
      //正方形左上角坐标
      const newRPositionWest = getNorthPointByDistance(
        newRPosition,
        -yesFlyRadius.value,
        "x"
      );
      //正方形左下角坐标
      const newRPositionNorth = getNorthPointByDistance(
        newRPositionWest,
        -(yesFlyRadius.value * 2),
        "y"
      );
      //网格间隔（m）=> 经度 、纬度
      let tempLng = yesFlyGird.value * latTureM;
      spaceLng.value = Number(tempLng.toFixed(4));
      let cartographicWestc =
        ellipsoid.cartesianToCartographic(newRPositionWest);
      let cartographicWesty = Cesium.Math.toDegrees(cartographicWestc.latitude);
      let cartographicNorthc =
        ellipsoid.cartesianToCartographic(newRPositionNorth);
      let cartographicNorthy = Cesium.Math.toDegrees(
        cartographicNorthc.latitude
      );
      let lngAllLong = cartographicWesty - cartographicNorthy;
      //分成了多少份
      allSpaceShare.value = Math.ceil(lngAllLong / spaceLng.value);
      if (allSpaceShare.value > 24) {
        ElMessage.error(t("1113"));
        return;
      }
      if (data.resultStatus === true) {
        createYesFlyCylinder(
          data.resultData.longitude,
          data.resultData.latitude,
          yesFlyRadius.value,
          yesFlynHeight.value,
          yesFlyGird.value,
          true
        );
      } else {
        (yesFlyRadius.value = ""),
          (yesFlynHeight.value = ""),
          (yesFlyGird.value = "");
      }

      handleYesFlyERegionShow.value = false;
      add_no_flv_div_show.value = false;
    }
    //取消保存可飞区
    function cancelSaveYesFlyRegion() {
      handleYesFlyERegionShow.value = false;
      add_no_flv_div_show.value = false;
      yesFlyRadius.value = "";
      yesFlynHeight.value = "";
      yesFlyGird.value = "";
    }
    /**
     * 区域逻辑 end
     */
    /**
     * cesium 操作----开始
     */
    // 点击删除所有的面实体
    function clearAll() {
      activeShapePoints.value.pop(); //去处最后一个
      viewer.entities.remove(floatingPoint.value); //动态点
      viewer.entities.remove(activeShape.value); //动态图层
      viewer.entities.remove(endPlolony.value); //动态图层
      viewer.entities.remove(newPloygon.value); //清楚删除点后重新生成的面
      floatingPoint.value = undefined;
      activeShape.value = undefined;
      activeShapePoints.value = [];
      // 取消所有的监听函数
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.RIGHT_CLICK);
    }
    //点击选中一个点
    function clickPoint(data) {
      noFlyPointList.value.forEach(e => {
        if (e.pointId === data.pointId && !e.isActive) {
          e.isActive = true
        } else if (e.pointId === data.pointId && e.isActive) {
          e.isActive = false
        } else {
          e.isActive = false
        }
      })
    }
    const newPloygon = ref('')
    function drawPolygon(positions) {
      var radar = viewer.entities.getById("new_ploygon");
      if (radar !== undefined) {
        viewer.entities.removeById("new_ploygon");
      }
      var polygonGeometry = viewer.entities.add({
        id: "new_ploygon",
        name: t("1114"),
        polygon: {
          // 绘制动态面实体时，面的位置信息必须是使用 Cesium.CallbackProperty() 和 Cesium.PolygonHierarchy() 返回的坐标
          hierarchy: new Cesium.PolygonHierarchy(positions),
          material: Cesium.Color.WHITE.withAlpha(0.7),
        },
      });
      newPloygon.value = polygonGeometry
    }
    const activeShapePoints = ref([]); //点
    const activeShape = ref(null); //动态图形
    const floatingPoint = ref(null);
    const endPlolony = ref(null); //最后生成的面
    const isDrawIng = ref(false); //是否在绘制中
    // 绘制多边形
    function drawPolygon1(whetherStart) {
      if (viewer) {
        if (whetherStart) {
          let whetherPoint = true;
          isDrawIng.value = true
          // 清除以前
          removeEntityByName("drawPolygon");
          removeEntityByName("drawPolygonPoint");
          viewer.screenSpaceEventHandler.setInputAction(function (movement) {
            let positionPoint = viewer.scene.pickPosition(movement.position);
            if (!positionPoint) {
              // 椭球位置
              const ellipsoid = viewer.scene.globe.ellipsoid;
              positionPoint = viewer.scene.camera.pickEllipsoid(
                movement.position,
                ellipsoid
              );
            }
            const earthPosition = positionPoint; //当前世界坐标笛卡尔积
            if (Cesium.defined(earthPosition)) {
              if (whetherPoint) {
                //是否可以点点
                if (activeShapePoints.value.length === 0) {
                  //没有点/开始绘制
                  floatingPoint.value = createPoint(earthPosition); //绘制点
                  activeShapePoints.value.push(earthPosition); //添加点
                  var dynamicPositions = new Cesium.CallbackProperty(
                    function () {
                      //使用callback,activeShapePoints变化会重新生成新的多边形并绘制
                      return new Cesium.PolygonHierarchy(
                        activeShapePoints.value
                      );
                    },
                    false
                  );
                  activeShape.value = drawShape(dynamicPositions); //绘制多边形
                }
                var v2 = noFlyPointList.value.find((value, index, arr) => {
                  return value.isActive
                });
                var i2 = noFlyPointList.value.findIndex((value) => value == v2);
                activeShapePoints.value.push(earthPosition);
                // 添加点实体
                addRoutePoint(earthPosition);
              } else {
                ElMessage.error(t("1115"));
              }
            }
          }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

          viewer.screenSpaceEventHandler.setInputAction(function (movement) {
            if (Cesium.defined(floatingPoint.value)) {
              // var newPosition = viewer.scene.pickPosition(event.endPosition);
              let positionPoint = viewer.scene.pickPosition(
                movement.endPosition
              );
              if (!positionPoint) {
                // 椭球位置
                const ellipsoid = viewer.scene.globe.ellipsoid;
                positionPoint = viewer.scene.camera.pickEllipsoid(
                  movement.endPosition,
                  ellipsoid
                );
              }
              // const newPosition = viewer.camera.pickEllipsoid(movement.endPosition, viewer.scene.globe.ellipsoid);//当前世界坐标笛卡尔积
              const newPosition = positionPoint; //当前世界坐标笛卡尔积
              if (Cesium.defined(newPosition)) {
                floatingPoint.value.position.setValue(newPosition); //更新点坐标
                activeShapePoints.value.pop(); //取出最后一个动态点
                activeShapePoints.value.push(newPosition); //添加新的动态点
                if (activeShapePoints.value.length > 2) {
                  // 判断多边形是否合法
                  // 取出最后两个点是结束线
                  const endLine = [
                    shijieToLon(
                      activeShapePoints.value[
                      activeShapePoints.value.length - 1
                      ]
                    ),
                    shijieToLon(
                      activeShapePoints.value[
                      activeShapePoints.value.length - 2
                      ]
                    ),
                  ];
                  // 最后一个点和第一个点是开始线
                  const startLine = [
                    shijieToLon(
                      activeShapePoints.value[
                      activeShapePoints.value.length - 1
                      ]
                    ),
                    shijieToLon(activeShapePoints.value["0"]),
                  ];
                  // 去掉最后一个点是已完成的线
                  const allLine = [];
                  activeShapePoints.value.forEach((item) => {
                    allLine.push(shijieToLon(item));
                  });
                  allLine.pop(); //去掉最后一个点
                  var line1 = turf.lineString(allLine);
                  var line2 = turf.lineString(startLine);
                  var line3 = turf.lineString(endLine);
                  var startIntersects = turf.lineIntersect(line1, line2);
                  var endIntersects = turf.lineIntersect(line1, line3);
                  if (
                    startIntersects.features.length > 1 ||
                    endIntersects.features.length > 1
                  ) {
                    whetherPoint = false;
                  } else {
                    whetherPoint = true;
                  }
                }
              }
            }
          }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

          // 重新绘制形状，使其不是动态的，然后删除动态形状。
          function terminateShape() {
            activeShapePoints.value.pop(); //去处最后一个
            endPlolony.value = drawShape(activeShapePoints.value);
            viewer.entities.remove(floatingPoint.value); //动态点
            viewer.entities.remove(activeShape.value); //动态图层
            floatingPoint.value = undefined;
            activeShape.value = undefined;
            activeShapePoints.value = [];
            isDrawIng.value = false
          }
          return new Promise((resolve, reject) => {
            viewer.screenSpaceEventHandler.setInputAction(function (event) {
              viewer.screenSpaceEventHandler.removeInputAction(
                Cesium.ScreenSpaceEventType.MOUSE_MOVE
              ); //停止监听移动事件
              viewer.screenSpaceEventHandler.removeInputAction(
                Cesium.ScreenSpaceEventType.LEFT_CLICK
              ); //停止监听移动事件
              // resolve(activeShapePoints.value)
              const data = []; //经纬度数组
              activeShapePoints.value.forEach((item) => {
                data.push(shijieToLon(item));
              });
              data.pop(); //去掉最后一个重复点
              const areaData = [...data, data["0"]]; //面积数组
              var polygon = turf.polygon([areaData]);
              // 面积(m²)
              var area = turf.area(polygon);
              const resolveData = {
                coordinates: data,
                area: area,
              };
              resolve(resolveData);
              terminateShape();
              setCursorStyle("default");
            }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
          });
        } else {
          removeEntityByName("drawPolygon");
          removeEntityByName("drawPolygonPoint");
          viewer.screenSpaceEventHandler.removeInputAction(
            Cesium.ScreenSpaceEventType.MOUSE_MOVE
          ); //停止监听移动事件
          viewer.screenSpaceEventHandler.removeInputAction(
            Cesium.ScreenSpaceEventType.LEFT_CLICK
          ); //停止监听移动事件
        }
      }
    }

    // 绘制点
    function createPoint(worldPosition) {
      var point = viewer.entities.add({
        name: "drawPolygonPoint",
        position: worldPosition,
        billboard: {
          image: GroundPoint,
          scale: 0.5,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          HeightReference: Cesium.HeightmapEncoding.CLAMP_TO_GROUND,
        },
      });
      return point;
    }

    // 世界坐标转经纬度
    function shijieToLon(newPosition) {
      const carto_position = Cesium.Cartographic.fromCartesian(newPosition);
      const longitude = Cesium.Math.toDegrees(carto_position.longitude); //四舍五入保留三位小数
      const latitude = Cesium.Math.toDegrees(carto_position.latitude);
      return [longitude, latitude];
    }

    // 绘制多边形
    function drawShape(positionData) {
      let shape = viewer.entities.add({
        name: "drawPolygon",
        polygon: {
          hierarchy: positionData,
          material: new Cesium.ColorMaterialProperty(
            Cesium.Color.WHITE.withAlpha(0.7)
          ),
        },
      });
      return shape;
    }
    // 根据name删除实体
    function removeEntityByName(value) {
      // 清除之前的实体
      const entitys = viewer.entities._entities._array;
      let length = entitys.length;
      // 倒叙遍历防止实体减少之后entitys[f]不存在
      for (let f = length - 1; f >= 0; f--) {
        if (entitys[f]._name && entitys[f]._name === value) {
          viewer.entities.remove(entitys[f]);
        }
      }
    }
    /**
     * 添加一个航点  -- 通过垂足点世界坐标添加
     * @param {* 空间坐标} position  -- Cartesian3
     * @param {* 地形点还是模型点} type  -- terrain | model
     * @param {* 序号} serialNum  -- number  (不传就是默认最后一个点)
     * @return 航点信息
     */
    function addRoutePoint(position, type, serialNum) {
      const routePointId = uuidv4(); //航点的id
      createGroundPoint(position, routePointId); //创建垂足点
    }
    /**
     * 创建垂地点
     * @param {* 世界坐标位置} position   -- Cartesian3
     * @param {* 航点id} routePointId   -- string
     */
    function createGroundPoint(position, routePointId) {
      const id = routePointId;
      const entity = viewer.entities.add({
        id: id,
        position: position,
        billboard: {
          image: GroundPoint,
          scale: 0.5,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          HeightReference: Cesium.HeightmapEncoding.CLAMP_TO_GROUND,
        },
        routePointId: routePointId,
      });
      let obj = {};
      obj.pointId = routePointId;
      obj.position = position;
      obj.name = t(`点${noFlyPointList.value.length + 1}`);
      obj.isActive = false;
      noFlyPointList.value.push(obj);
    }

    //创建禁飞区多边形体
    function createNoFlyCylinder(data, height, color) {
      var ellipsoid = viewer.scene.globe.ellipsoid;
      let positionArr = [];
      data.coordinateObj.forEach((e) => {
        //经纬度转世界坐标
        var cartographic = Cesium.Cartographic.fromDegrees(e.lng, e.lat, e.alt);
        var cartesian3 = ellipsoid.cartographicToCartesian(cartographic);
        positionArr.push(cartesian3);
      });
      var cuboid = viewer.entities.add({
        name: data.fenceName,
        id: data.regionId,
        polygon: {
          hierarchy: new Cesium.PolygonHierarchy(positionArr),
          height: 0,
          extrudedHeight: data.height === null ? 500 : data.height,
          outline: true,
          outlineWidth: 100,
          outlineColor:
            Cesium.Color.fromCssColorString("#ffffff").withAlpha(0.4),
          arcType: Cesium.ArcType.RHUMB,
          material:
            color === "red"
              ? new Cesium.ColorMaterialProperty(
                Cesium.Color.fromCssColorString("#e85368").withAlpha(0.4)
              )
              : new Cesium.ColorMaterialProperty(
                Cesium.Color.fromCssColorString("#eec817").withAlpha(0.4)
              ),
        },
      });
    }
    //创建可飞区圆柱体
    async function createYesFlyCylinder(
      lng,
      lat,
      radius,
      height,
      Gird,
      status
    ) {
      if (yesFlyList.value.length === 1) {
        viewer.entities.removeById("yes_fly");
      }
      var cuboid = viewer.entities.add({
        name: t("69"),
        id: "yes_fly",
        position: Cesium.Cartesian3.fromDegrees(lng, lat),
        cylinder: {
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          topRadius: radius,
          bottomRadius: radius,
          length: height,
          material: new Cesium.ColorMaterialProperty(
            Cesium.Color.fromCssColorString("#03caca").withAlpha(0.2)
          ),
        },
      });
      if (status) {
        const yesFlyRegionId = uuidv4(); //航点的id
        let obj = {};
        obj.lng = lng;
        obj.lat = lat;
        obj.regionId = yesFlyRegionId;
        obj.radius = radius;
        obj.height = height;
        obj.interval = Gird;
        if (yesFlyList.value.length === 0) {
          let res = await fence.addFlyAble(obj, detailData.value.sn);
          if (res.resultStatus) {
            ElMessage.success(t("1116"));
            checkAllYesFly.value = true;
          }
        } else if (yesFlyList.value.length === 1) {
          let res = await fence.editFlyAble(obj, yesFlyList.value[0].regionId);
          if (res.resultStatus) {
            ElMessage.success(t("714"));
            checkAllYesFly.value = true;
          }
        }
      }
      let resFence = await fence.getFenceList({
        sn: detailData.value.sn,
      });
      if (resFence.resultStatus) {
        yesFlyList.value = resFence.resultData[1];
      }
    }
    //创建可飞区网格
    function createYesFlyGrid(
      data,
      lngWest,
      lngNorth,
      latWest,
      latX,
      lngX,
      latNorth,
      Gird
    ) {
      const alphas = [];
      let start = "A".charCodeAt(0); //65
      let last = "Z".charCodeAt(0); //90

      for (start; start <= last; start++) {
        alphas.push(String.fromCharCode(start));
      }
      //每隔20读绘制一条经度线和经度标注,自己控制间隔
      let flag = 0;
      let flagZM = 0;
      for (
        let lang = lngWest;
        lang <= lngX + spaceLng.value;
        lang = lang + spaceLng.value
      ) {
        flag = flag + 1;
        let link = viewer.entities.add({
          polyline: {
            positions: Cesium.Cartesian3.fromDegreesArray([
              lang,
              latX,
              lang,
              latNorth,
            ]),
            width: 3.0,
            material: Cesium.Color.fromCssColorString("#FFFFFF").withAlpha(1),
            clampToGround: true,
          },
        });
        entityGird.value.push(link);
        //取线postions集合，取其1/2处的postion
        var position =
          link._polyline._positions._value[
          link._polyline._positions._value.length - 2 / 2
          ];
        //生成点
        if (flag <= allSpaceShare.value + 1) {
          let point = viewer.entities.add({
            //点
            position: position,
            point: {
              pixelSize: 1, //设置小点  基本看不见
              color: new Cesium.Color(0.0, 0.0, 0.0, 0.0), //颜色设置透明
            },
            label: {
              text: `${flag}`, //描述内容
              font: "normal 18px MicroSoft YaHei",
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              fillColor: Cesium.Color.WHITE,
              pixelOffset: new Cesium.Cartesian2(25, 0), //偏移量
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                5.0,
                5000.0
              ),
            },
          });
          entityGird.value.push(point);
        }
      }
      //每隔10读绘制一条纬度线和纬度标注,自己控制间隔
      for (let lat = latNorth; lat <= latX; lat = lat + spaceLng.value) {
        flagZM = flagZM + 1;
        let link = viewer.entities.add({
          polyline: {
            positions: Cesium.Cartesian3.fromDegreesArray([
              lngWest,
              lat,
              lngX,
              lat,
            ]),
            width: 1.0,
            material: Cesium.Color.fromCssColorString("#FFFFFF").withAlpha(1),
            clampToGround: true,
          },
        });
        entityGird.value.push(link);
        //取线postions集合，取其1/2处的postion
        var position = link._polyline._positions._value[0];
        //生成点
        if (flagZM < allSpaceShare.value) {
          let point = viewer.entities.add({
            //点
            position: position,
            point: {
              pixelSize: 1, //设置小点  基本看不见
              color: new Cesium.Color(0.0, 0.0, 0.0, 0.0), //颜色设置透明
            },
            label: {
              text: `${alphas[flagZM - 1]}`, //描述内容
              font: "normal 18px MicroSoft YaHei",
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              fillColor: Cesium.Color.WHITE,
              pixelOffset: new Cesium.Cartesian2(-25, -20), //偏移量
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                5.0,
                5000.0
              ),
            },
          });
          entityGird.value.push(point);
        }
      }
    }
    //删除实体
    function delNoFlyCylinder(data) {
      viewer.entities.removeById(data.regionId);
    }
    /**
     * 设置cesium鼠标样式
     * @param {* 样式名} style  -- string
     */
    function setCursorStyle(style) {
      viewer._element.style.cursor = style;
    }
    //删除实体点
    function deltePoint(data) {
      if (isDrawIng.value) {
        ElMessage.info(t('1117'))
        return
      }
      viewer.entities.removeById(data.pointId);
      noFlyPointList.value = noFlyPointList.value.filter(
        (item) => item.pointId != data.pointId
      );
      if (noFlyPointList.value.length === 2) {
        clearAll();
        cancelSaveNoFlyRegion()
        viewer.entities.removeById("new_ploygon");
        noFlyPointList.value = []
        ElMessage.info(t('1118'))
        return
      }
      let positionArr = [];
      noFlyPointList.value.forEach((e) => {
        positionArr.push(e.position);
      });
      clearAll();
      drawPolygon(positionArr);
    }
    /**
     * cesium 操作----结束
     */
    return {
      ...toRefs(state),
      ...toRefs(methods),
      clickPoint,
      checkAllGird,
      handleCheckAllChangeGird,
      checkAllYesFly,
      checkAllHeightLimit,
      handleCheckAllChangeYesFly,
      isIndeterminateHeightLimit,
      handleCheckAllChangeHeightLimit,
      handleCheckAllChange,
      isIndeterminate,
      checkAll,
      delHeightLimitRegion,
      changeHeightLimit,
      delNoFlyRegion,
      changeNoFly,
      noFlyList,
      sureSaveYesFlyRegion,
      cancelSaveYesFlyRegion,
      yesFlynHeight,
      yesFlyGird,
      yesFlyRadius,
      handleYesFlyERegionShow,
      yesFlyList,
      handleYesFlyERegion,
      sureSaveNoFlyRegion,
      cancelSaveNoFlyRegion,
      delNoFlyPoint,
      addNoFlyRegion,
      noFlyPointList,
      noFlyName,
      add_no_flv_div_show,
      openNoFlyShow,
      no_fly_div_show,
      openHeightLimitShow,
      heightLimitList,
      heightLimitRegionHeight,
      isHeightLimitShowHeight,
      height_limit_div_show,
      yes_fly_div_show,
      openYesFlyShow,
      cityFormat,
      refreshing,
      getPosition,
      textHide,
      changeLayarVisible,
      zoomToLayer,
      layerFilterWord,
      filterLayer,
      layerChange,
      getJobInfo,
      devsiceSn,
      latText,
      latTure,
      changeLat,
      lngText,
      lngTure,
      changeLng,
      numberText,
      numberTrue,
      changeNumber,
      cityRef,
      teamRef,
      getDeviceList,
      changeSecurityHeigth,
      securityText,
      securityTure,
      securityHeightChange
    };
  },
});
</script>

<style lang="less" scoped>
@keyframes fadenum12 {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes fadenum13 {
  75% {
    width: 75%;
  }

  100% {
    width: 100%;
  }
}

// 详情弹窗
.detail {
  position: absolute;
  top: 0;
  left: 0;
  right: -3.25rem;
  bottom: -1.875rem;
  z-index: 888;
  background: #06151e;
  animation: fadenum12 0.8s 1;
  box-sizing: border-box;
  padding-right: 1.25rem;

  //  background: rgba(0, 28, 49, 0.8);
  // 详情选择地区样式
  /deep/.el-input__wrapper {
    border-radius: 0px !important;
    height: 2.375rem !important;
    border-radius: 5px !important;
  }

  /deep/.el-input__inner {
    color: #ffffff;
  }

  .close {
    height: 3.5625rem;
    width: 100%;

    .cicon {
      color: #ffffff;
      display: block;
      font-size: 1.75rem;
      margin-right: 1.3125rem;
    }
  }

  .detailBox {
    width: calc(100% - 0.4rem);
    height: calc(100% - 3.5625rem);

    .boxLeft {
      position: relative;
      width: 73.5%;
    }

    .boxRight {
      width: 26.5%;
      overflow-y: auto;

      // 头部标题
      span {
        font-size: 1.375rem;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
      }

      .h_icon {
        color: #24b5ee;
        font-size: 1.375rem;
      }

      .Rheader {
        height: 4.625rem;
        box-sizing: border-box;
        padding: 1.6875rem 1.4375rem 1.5625rem 2rem;
        border-bottom: 1px solid #003335;

        .btn {
          width: 5.8125rem;
          height: 2.0625rem;
          background: rgba(0, 28, 49, 0.7);
          border: 1px solid #00f5ff;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
          line-height: 2.0625rem;
        }

        .btn_left {
          margin-left: 0.9375rem;
        }
      }

      .h_content {
        width: 100%;
        padding: 1.25rem 1.3125rem 0px 1.9375rem;

        .list {
          position: relative;
          height: 2.375rem;
          margin-bottom: 0.875rem;
          padding-left: 8.6875rem;

          .listName {
            position: absolute;
            top: 0;
            left: 0;
            width: 8.6875rem;
            color: #999999;
            height: 2.375rem;
            line-height: 2.375rem;
          }

          .listContent {
            width: 100%;
            box-sizing: border-box;
            color: #ffffff;
            font-size: 1.125rem;
            position: relative;

            span {
              position: absolute;
              left: 0;
              bottom: -0.875rem;
              font-size: 0.75rem;
              color: #f56c6c;
            }
          }
        }

        .list:last-child {
          margin-bottom: 1.25rem;
        }
      }

      // 修改input样式
      .rolePut {
        width: 100%;
        height: 2.375rem;
        box-sizing: border-box;
        border: 1px solid #25a7d5;
        padding-left: 1.0625rem;
        background: #0d4460;
        color: #ffffff;
        outline: 0;
      }

      input::-webkit-input-placeholder {
        color: #a9b8c3;
      }

      .homeWork {
        box-sizing: border-box;
        padding: 0.625rem 1.4375rem 1.375rem 2rem;
        border-bottom: 1px solid #003335;
      }
    }
  }
}

.otherbox {
  height: 100%;
  position: absolute;
  top: 0;
  right: -3.25rem;
  bottom: -1.875rem;
  z-index: 888;
  background: #06151e;
  box-sizing: border-box;
  padding-right: 1.25rem;
}

.others {
  width: 31.625rem;

  // 详情选择地区样式
  /deep/.el-input__wrapper {
    border-radius: 0px !important;
    height: 2.375rem !important;
  }

  /deep/.el-input__inner {
    color: #ffffff !important;
  }

  .orther_r {
    margin-right: 1.3125rem;
  }

  // animation: fadenum13 1.5s 1;
  .close {
    height: 3.5625rem;
    width: 100%;

    .cicon {
      display: block;
      color: #ffffff;
      font-size: 1.75rem;
      margin-right: 1.3125rem;
    }
  }

  // 头部标题
  span {
    font-size: 1.375rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
  }

  .h_icon {
    color: #24b5ee;
    font-size: 1.375rem;
  }

  .Rheader {
    width: 100%;
    height: 4.625rem;
    box-sizing: border-box;
    padding: 1.6875rem 1.4375rem 1.5625rem 2rem;
    border-bottom: 1px solid #003335;

    .btn {
      width: 5.8125rem;
      height: 2.0625rem;
      background: rgba(0, 28, 49, 0.7);
      border: 1px solid #00f5ff;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      line-height: 2.0625rem;
    }

    .btn_left {
      margin-left: 0.9375rem;
    }
  }

  .h_content {
    width: 100%;
    padding: 1.25rem 1.3125rem 0px 1.9375rem;

    .list {
      position: relative;
      height: 2.375rem;
      margin-bottom: 0.875rem;
      padding-left: 8.6875rem;

      .listName {
        position: absolute;
        top: 0;
        left: 0;
        width: 8.6875rem;
        color: #999999;
        height: 2.375rem;
        line-height: 2.375rem;
      }

      .listContent {
        width: 100%;
        box-sizing: border-box;
        color: #ffffff;
        font-size: 1.125rem;
        position: relative;

        span {
          position: absolute;
          left: 0;
          bottom: -0.875rem;
          font-size: 0.75rem;
          color: #f56c6c;
        }
      }
    }
  }

  // 修改input样式
  .rolePut {
    width: 100%;
    height: 2.375rem;
    box-sizing: border-box;
    border: 1px solid #25a7d5;
    padding-left: 1.0625rem;
    background: #0d4460;
    color: #ffffff;
    outline: 0;
  }

  input::-webkit-input-placeholder {
    color: #a9b8c3;
  }

  .homeWork {
    box-sizing: border-box;
    padding: 0.625rem 1.4375rem 1.375rem 2rem;
    border-bottom: 1px solid #003335;
  }

  // }
}

.relative_form {
  position: relative;

  .position_div {
    position: absolute;
    top: 0.375rem;
    right: 0.4375rem;
    cursor: pointer;

    .element_icon {
      top: -2px;
      margin-right: 3px;
      vertical-align: middle;
      cursor: pointer;
    }

    .rotate_icon {
      animation: rotateicon 1s infinite linear;
    }

    // 旋转动画
    @keyframes rotateicon {
      from {
        transform: rotateZ(360deg);
      }

      to {
        transform: rotateZ(0deg);
      }
    }
  }
}

.el_input_number {

  /deep/ .el-input-number__decrease,
  /deep/ .el-input-number__increase,
  /deep/ .el-input__wrapper {
    background: #0d4460;
    color: #fff;
    border-color: #51d2ff;
  }

  /deep/ .el-input__wrapper {
    box-shadow: 0 0 0 1px #51d2ff inset;
  }
}

.el_slider {
  width: 12.5rem;
}

.layer_div {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  z-index: 1;
  background: #0b476b;
  border-radius: 0.25rem;
}

// 图层
.codeImg {
  width: 18.75rem;
  // height: 18.75rem;
  box-sizing: border-box;
  padding-top: 1.25rem;

  .elsearch {
    width: 100%;
    height: 2.25rem;
    padding: 0 0.875rem;

    // line-height: 2.25rem;
    .filePut {
      width: 13.25rem;
      height: 2.25rem;
      box-sizing: border-box;
      border: 0px solid #51d2ff;
      outline: 0;
      font-size: 0.875rem;
    }

    input::-webkit-input-placeholder {
      color: #c9c9c9;
    }

    .file_icon {
      width: 3.625rem;
      height: 2.25rem;
      color: #ffffff;
      background: #38adf9;
      border-radius: 0px 4px 4px 0px;
      font-weight: 500;
      font-size: 1.375rem;
    }

    /deep/.el-input {
      width: 100% !important;
    }

    /deep/.el-input__wrapper {
      width: 100% !important;
      border: 0px solid #51d2ff;
      box-shadow: 0 0 0 0;
      border-radius: 4px 0 0 4px;
      height: 2.25rem !important;
    }

    /deep/.el-input__inner {
      width: 100% !important;
      height: 2.25rem !important;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .laylist {
    height: 16.5625rem;
    overflow: hidden;
    overflow: auto;
    box-sizing: border-box;

    .itemTop {
      padding: 0 0.875rem;
      margin-top: 1.5rem;
      cursor: pointer;

      span {
        color: #00f5ff;
        font-size: 1rem;
      }
    }

    .itemTop:hover {
      background: #38adf9;

      span {
        color: #fff;
      }
    }
  }
}

.region_div {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;

  .region_div_list {
    margin-top: 10px;
    width: 200px;

    .no_fly_div {
      width: 200px;
      height: 200px;
      background: #0b476b;
      margin-top: 5px;
      border-top-left-radius: 5px;
      /* 左上角 */
      border-top-right-radius: 5px;
      /* 右上角 */
      position: relative;

      .checkBox_div {
        height: 80%;
        overflow-y: scroll;
        box-sizing: border-box;

        .itemTop {
          padding: 0 0.875rem;
          margin-top: .2rem;
          position: relative;
          cursor: pointer;

          .region_text_text {
            color: #00f5ff;
            font-size: 1rem;
            display: inline-block;
            width: 45%;
            margin-left: 10px;
          }

          .region_text_del {
            color: #ffffff;
            font-size: 1rem;
            display: inline-block;
            position: absolute;
            right: 10px;
          }
        }

        .itemTop:hover {
          background: #38adf9;

          .region_text_text {
            color: #fff;
          }
        }

        .no_fly_name {
          width: 100%;
          padding: 10px 10px 0px 10px;

          span {
            display: inline-block;
            width: 30%;
          }

          :deep(.el-input__inner) {
            color: #000;
            border-radius: 5px;
          }
        }
      }

      .bottom_button {
        width: 100%;
        position: absolute;
        bottom: 0;

        :deep(.el-button:not(.is-text)) {
          width: 100%;
        }
      }
    }

    .add_name_div {
      width: 200px;
      height: 300px;
      background: #0b476b;
      margin-top: 5px;
      border-top-left-radius: 5px;
      /* 左上角 */
      border-top-right-radius: 5px;
      /* 右上角 */
      position: relative;

      .no_fly_name {
        width: 100%;
        padding: 10px 10px 10px 10px;

        span {
          display: inline-block;
          width: 30%;
        }

        :deep(.el-input__inner) {
          color: #000;
          border-radius: 5px;
        }
      }

      .checkBox_div {
        height: 66%;
        overflow-y: scroll;
        box-sizing: border-box;
      }

      .checkBox_div1 {
        height: 50%;
        overflow-y: scroll;
        box-sizing: border-box;
      }

      .itemTop {
        padding: 0 0.875rem;
        height: 30px;
        margin-bottom: 0.5rem;
        position: relative;
        cursor: pointer;

        .region_text_text {
          color: #00f5ff;
          font-size: 1rem;
          display: inline-block;
          width: 45%;
          margin-left: 10px;
        }

        .region_text_del {
          color: #ffffff;
          font-size: 1rem;
          display: inline-block;
          position: absolute;
          right: 10px;
        }
      }

      .itemTop:hover {
        background: #38adf9;

        .region_text_text {
          color: #fff;
        }
      }

      .isActive {
        background: #38adf9;

        .region_text_text {
          color: #fff;
        }
      }

      .bottom_button {
        width: 100%;
        position: absolute;
        bottom: 0;
        padding: 0px 10px 10px 10px;

        :deep(.el-button:not(.is-text)) {
          width: 40%;
        }
      }
    }

    .yes_fly_div {
      width: 200px;
      height: 30px;
      background: #0b476b;
      margin-top: 5px;
      border-top-left-radius: 5px;
      /* 左上角 */
      border-top-right-radius: 5px;
      /* 右上角 */
      position: relative;

      .bottom_button {
        width: 100%;
        position: absolute;
        bottom: 0;

        :deep(.el-button:not(.is-text)) {
          width: 100%;
        }
      }
    }

    .region_text {
      display: inline-block;
      width: 100%;
      text-align: center;
      border-radius: 5px;
      background: #0b476b;
      height: 32px;
      cursor: pointer;
      position: relative;

      :deep(.el-checkbox) {
        color: #fff;
        position: absolute;
        left: 10px;
      }

      .icon_style {
        position: absolute;
        left: 90%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

b {
  margin-left: 10px;
}
</style>
