<template>
  <div class="container">
    <div class="header">
      <div class="top flex-sb">
        <h3>{{ t('163') }}</h3>
        <div class="flex-sb">
          <div class="icon_btn blue_btn" @click="addMember" v-checkAuth="'device-info-add'">
            <el-icon class="element_icon"> <CirclePlusFilled /> </el-icon
            >&nbsp;{{ t('209') }}
          </div>
          <div class="icon_btn red_btn" @click="batchDel" v-checkAuth="'device-info-delete'">
            <el-icon class="element_icon"> <Delete /> </el-icon>&nbsp;{{ t('210') }}
          </div>
        </div>
      </div>
      <div class="footer flex-fs">
        <div
          class="navlists"
          style="cursor: pointer"
          :class="{ activte: $route.matched[1].path === item.path }"
          v-for="item in navlist"
          :key="item.id"
          @click="jump(item)"
        >
          {{ item.name }}
        </div>
        <div class="search flex-a">
          <el-input
            style="100%"
            class="rolePut common-input"
            type="text"
            :placeholder="t('211')"
            v-model.trim="queryForm.queryInfo"
            clearable
            @clear="input_clean"
            @keyup.enter="search"
          />
          <el-icon class="btn" style="cursor: pointer" @click="search"
            ><Search
          /></el-icon>
        </div>
        <div class="city flex-a">
          <span>{{ t('212') }}:</span>
          <el-cascader
            ref="cityRef"
            class="common-input cityc"
            style="width: 65%"
            :show-all-levels="false"
            v-model="cityVal"
            :placeholder="t('213')"
            :options="cityList"
            :props="cityCasDer"
            clearable
            @change="cityChange"
            popper-class="teamedit disabled_hide_cacader"
          />
        </div>
        <div class="team flex-a">
          <span>{{ t('214') }}:</span>
          <el-cascader
            ref="teamRef"
            class="common-input teamc"
            style="width: 65%"
            :show-all-levels="false"
            :placeholder="t('175')"
            v-model="teamVal"
            :props="organizationCasDer"
            :options="teamList"
            clearable
            @change="teamChange"
            popper-class="teamedit disabled_hide_cacader"
          />
        </div>
        <div class="equipment flex-a">
          <span>{{ t('215') }}:</span>
          <el-select
            style="width: 65%"
            v-model="queryForm.modelId"
            class="m-2 common-input"
            :placeholder="t('175')"
            @change="modelChange"
            clearable
            @clear="onClean"
          >
            <el-option
              v-for="item in modelList"
              :key="item.id"
              :label="item.model"
              :value="item.id"
            />
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { t } from '../../languages';
import { defineComponent, onMounted, reactive, toRefs, watchEffect, ref } from "vue";
import { router } from "@/router";
import emitter from "../../utils/mitt";
import device from "../../network/device";
import member from "../../network/member";
import { store } from "@/store";
import {
  findP,
  flatten,
  openRegionAdcode,
  findChildren,
} from "@/utils/openRegion";
export default defineComponent({
  props: {
    navlist: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dialogType: {
      type: String,
      default: () => {},
    },
  },
  emits: ["on-clean", "addUAV", "searchChange", "deviceDel"],
  setup(props, { emit }) {
    const cityRef = ref(null);
    const teamRef = ref(null);
    const effect = async () => {
      if (props.dialogType == "fly") {
        let res = await device.getUavModelList();
        state.modelList = res.resultData;
        state.queryForm.type = "UAV";
      }
      if (props.dialogType == "airport") {
        let res = await device.getAirportModelList();
        state.modelList = res.resultData;
        state.queryForm.type = "AIRPORT";
      }

      if (props.dialogType == "asset") {
        let res = await device.getOtherModelList();
        state.modelList = res.resultData;
        state.queryForm.type = "OTHER";
      }
    };
    emitter.on("refreshDevice", () => {
      effect();
    });
    watchEffect(effect);

    function initCity() {
      fetch("json/area-tree.json")
      .then((d) => d.json())
      .then((res) => {
        const arr = store.state.system.openRegion;
        const isCountry = arr.some((item) => {
          return item === 1;
        });
        if (isCountry) {
          state.cityList = res[0].children;
        } else {
          const arr1 = arr.map((item) => {
            return findP(item, res[0].children);
          });
          const treeAdcode = openRegionAdcode(res[0].children); //这个树的全部adcode
          const arr2 = flatten(arr1); //需要的  ,这里还去少区数据
          const arr3 = arr2.map((item) => {
            return findChildren(item, res[0].children); //补上缺少的区adcode
          });

          const needAdcode = Array.from(new Set([...arr2, ...flatten(arr3)]));
          const notAdcode = treeAdcode.filter(
            (v) => needAdcode.findIndex((el) => el === v) === -1
          ); //去重过滤需要的adcode
          const city = deepCopyIsOpenRegion(res[0].children); //添加禁用属性
          const needCity = delIsOpenRegion(city);

          state.cityList = needCity;

          // 递归遍历修改数据
          function deepCopyIsOpenRegion(source) {
            const target = []; //Array.isArray(source) ? [] : {}; //数组兼容
            for (const k in source) {
              if (typeof source[k] === "object") {
                target[k] = deepCopyIsOpenRegion(source[k]);
              } else {
                target[k] = source[k];
              }
            }
            if (notAdcode.length != 0) {
              notAdcode.map((item) => {
                if (target.adcode === item) {
                  target.disabled = true;
                }
              });
            }
            return target;
          }
        }

        // 去除禁用的数据
        function delIsOpenRegion(source) {
          const target = []; //Array.isArray(source) ? [] : {}; //数组兼容
          source.map((item) => {
            if (item.disabled != true) {
              target.push(item);
            }
          });
          return target;
        }
        // state.cityList = res[0].children;
      });
    }
    initCity();
    // 防止刷新数据丢失开放区域造成数据渲染不出来
    emitter.on("getOpenRegion", () => {
      initCity();
    });
    onMounted(() => {
      methods.teamData();
    });
    const state = reactive({
      isType: "", //类型
      keyWord: "", //搜索关键字
      cityVal: "", //city选中值
      cityList: [], //所在区域
      teamList: [], //所属团队
      teamVal: "", //团队选中值
      modelList: [], //设备型号
      modelVal: "", //设备选中值
      queryForm: {
        modelId: "",
        queryInfo: "",
        region: "",
        teamId: "",
        type: "",
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      // 修改cascader默认配置
      cityCasDer: {
        value: "name",
        label: "title",
        checkStrictly: true,
        children: "children",
        expandTrigger: "hover",
      },
      // 修改cascader默认配置
      organizationCasDer: {
        value: "id",
        label: "teamName",
        checkStrictly: true,
        children: "children",
        expandTrigger: "hover",
      },
    });
    const methods = reactive({
      jump: (item) => {
        router.push(item.path);
        state.isType = item.type;
      },
      //所属团队
      teamData: async () => {
        let res = await member.getTreeList();
        state.teamList = res.resultData;
      },
      // 添加
      addMember: () => {
        emit("addUAV");
      },
      // 删除
      batchDel: () => {
        emit("deviceDel");
      },
      // 搜索关键字
      search: () => {
        emit("searchChange", state.queryForm);
      },
      input_clean: () => {
        emit("searchChange", state.queryForm);
      },
      //区域选择事件
      cityChange: (e) => {
        // 选中与清空
        if (e !== null) {
          if (e.length == 1) {
            state.queryForm.region = `${e[0]}`;
          } else if (e.length == 2) {
            state.queryForm.region = `${e[0]};${e[1]}`;
          } else if (e.length == 3) {
            state.queryForm.region = `${e[0]};${e[1]};${e[2]}`;
          }
          // state.queryForm.region = `${e[0]};${e[1]};${e[2]}`;
        } else {
          state.queryForm.region = "";
          cityRef.value.togglePopperVisible();
        }
        emit("searchChange", state.queryForm);
        cityRef.value.togglePopperVisible();
      },
      // 团队选择事件
      teamChange: (e) => {
        // 选中与清空
        if (e != null) {
          let len = e.length - 1;
          state.queryForm.teamId = e[len];
        } else {
          state.queryForm.teamId = "";
          teamRef.value.togglePopperVisible();
        }
        emit("searchChange", state.queryForm);
        teamRef.value.togglePopperVisible();
      },
      // 设备类型选择事件
      modelChange: () => {
        emit("searchChange", state.queryForm);
      },
      // 清楚选中的数据
      onClean: () => {
        emit("on-clean");
      },
    });
    return {
      ...toRefs(state),
      ...toRefs(methods),
      cityRef,
      teamRef
    };
  },
});
</script>

<style lang="less">
//新建任务下拉框高度
.el-select-dropdown__wrap {
  max-height: 12.5rem !important;
}
</style>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 7.125rem;
  /deep/.el-input__wrapper {
    height: 2.5rem;
  }
}
.header {
  width: 100%;
  height: 7.125rem;
  padding: 1rem 2rem 0 2.0625rem;
  box-sizing: border-box;
  .top {
    height: 1.25rem;
  }
  h3 {
    font-size: 1.25rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #36e5ff;
  }
  .element_icon {
    font-size: 1.125rem;
  }
  .footer {
    width: 100%;
    margin-top: 1.375rem;
    .navlists {
      width: 7.1875rem;
      height: 2.5rem;
      background: #0d4460;
      border: 1px solid #51d2ff;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .activte {
      background: #0090da;
    }

    .navlists:nth-child(1) {
      border-radius: 4px 0px 0px 4px;
      border-right: none;
    }
    .navlists:nth-child(2) {
      border-radius: 0px 4px 4px 0px;
      border-left: none;
    }
    .search {
      margin-left: 3.5625rem;
      /deep/.el-input__wrapper {
        border-radius: 4px 0px 0px 4px !important;
      }
      .rolePut {
        width: 15rem;
        height: 2.5rem;
        box-sizing: border-box;
        background: #0d4460;
        color: #ffffff;
        outline: 0;
      }
      input::-webkit-input-placeholder {
        color: #a9b8c3;
      }
      .btn {
        width: 4.625rem;
        height: 2.5rem;
        background: #0090da;
        border-radius: 0px 4px 4px 0px;
        color: #ffffff;
        font-size: 1.625rem;
      }
    }
    .city {
      margin-left: 2.8125rem;
      color: #35e1fb;
      span {
        display: block;
        margin-right: 0.875rem;
      }
    }
    .team {
      span {
        display: block;
        margin-right: 0.875rem;
      }
      margin-left: 0.9375rem;
      color: #35e1fb;
    }
    .equipment {
      margin-left: 0.9375rem;
      color: #35e1fb;
      span {
        display: block;
        margin-right: 0.875rem;
      }
    }
  }
}
.select-wrapper {
  /deep/ .label {
    margin-left: 0 !important;
  }
  /deep/.el-input__wrapper {
    width: 11.25rem !important;
  }
}
</style>